export const NOTIFSCOLUMNS = [
  {
    Header: "Status",
    accessor: "status",
    Cell: (row) => {
      switch (row.value) {
        case "notStarted":
          return <span className="text-danger">Not Started</span>;
        case "completed":
          return <span className="text-success">Completed</span>;
        default:
          return "N/A";
      }
    },
  },
  {
    Header: "Type",
    accessor: "type",
    Cell: (row) => {
      switch (row.value) {
        case "feelsick":
          return "I Feel Sick";
        case "appt_help":
        case "other_appt":
          return "I Need Help with My Appointments";
        case "other_med":
        case "med_help":
          return "I Need Help with My Medications";
        case "homesurvey":
          return "I Just Got Home";
        case "response":
          return "Responding to Care Message";
        default:
          return "N/A";
      }
    },
  },
  {
    Header: "Sent At",
    accessor: "created_at",
    Cell: (row) => {
      let event = new Date(row.value);
      return event.toLocaleString("en-US");
    },
  },
];
