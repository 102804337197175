// Root
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

// Components
import App from "./App";

// External Libraries
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// React Query
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

// Amplify
import Amplify, { toast } from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const queryClient = new QueryClient();

ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <App />
      <ToastContainer />
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
