import React from "react";
import { Container } from "reactstrap";
import { HeaderMain } from "./common/UI/headerMain";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Modal,
  Form as FormReact,
  Card,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import _, { each } from "lodash";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Services
import * as api from "../service/facilityService";

// Components
import LoadingSpinner from "./common/UI/loadingSpinner";
import { useState } from "react";

const YourFacilities = () => {
  const [visible, setVisible] = useState(false);
  const [facilityToShow, setFacility] = useState({
    name: "",
  });

  const navigate = useNavigate();
  const { mutate: addFacility } = useMutation(api.addFacility, {
    onSuccess: () => {
      queryClient.invalidateQueries("onBoardFacilityList");
      toast.success("Facility Added Successfully");
    },
    onError: () => {
      toast.error("Facility Failed to Add");
    },
  });
  const queryClient = useQueryClient();

  const onAddHandler = (data) => {
    console.log(data);
    addFacility(data);

    // navigate('/admin/onboard');
  };

  const facilities = useQuery(
    ["onBoardFacilityList"],
    () => api.getAllFacilities(),
    {
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  const showFacilityHandler = (id) => {
    let facility = facilities.data.filter((e) => e.id == id);
    setFacility(facility[0]);
    setVisible(true);

    console.log(facility);
  };

  return (
    <React.Fragment>
      <Container className="mt-5 mb-5">
        <HeaderMain
          title="Your Skilled Nursing Facilities"
          subTitle="Please see the details of your assigned facilities below. Click on each facility to see further details."
        />

        <div className="mt-5">
          {facilities.isFetching === true ? <LoadingSpinner /> : <></>}
          {facilities.isLoading === false ? (
            <div
              className="p-3 mb-5"
              style={{ border: "1px solid lightgrey", borderRadius: "5px" }}
            >
              <h3 className="fw-light">Assigned Skilled Nursing Facilities</h3>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="fw-bold">Name</th>
                    <th className="fw-bold">Address</th>
                    <th className="fw-bold">Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {facilities.data.map((ea) => (
                    <tr
                      onClick={() => showFacilityHandler(ea.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{ea.name}</td>
                      <td>
                        {ea.address} {ea.city} {ea.state} {ea.zip}
                      </td>
                      <td>{ea.facility_admin_phone}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Container>

      <Modal
        isOpen={visible}
        size="lg"
        centered
        toggle={() => setVisible(!visible)}
      >
        <ModalHeader>
          <div className="d-flex">
            <h3 className="" style={{ color: "#107869" }}>Facility: {facilityToShow.name}</h3>
          </div>
        </ModalHeader>
        <ModalBody>
          <h5 className="fw-light">
            <span className="fw-bold">Name:</span> {facilityToShow.name}
          </h5>
          <h5 className="fw-light">
            <span className="fw-bold">Address:</span> {facilityToShow.address},{" "}
            {facilityToShow.city} {facilityToShow.state}, {facilityToShow.zip}
          </h5>
          <h5 className="fw-light">
            <span className="fw-bold">Created on:</span>{" "}
            {facilityToShow.created_at}
          </h5>
          <hr></hr>
          <h3>Facility Contacts</h3>
          <h5 className="fw-light">
            <span className="fw-bold">Facility Admin: </span>
            {facilityToShow.facility_admin}, Phone:{" "}
            {facilityToShow.facility_admin_phone}
          </h5>
          <h5 className="fw-light">
            <span className="fw-bold">Medical Director: </span>
            {facilityToShow.director_medical}, Phone:{" "}
            {facilityToShow.director_medical_phone}
          </h5>
          <h5 className="fw-light">
            <span className="fw-bold">Director of Nursing: </span>
            {facilityToShow.director_nursing}, Phone:{" "}
            {facilityToShow.director_nursing_phone}
          </h5>
          <h5 className="fw-light">
            <span className="fw-bold">Mid Level: </span>
            {facilityToShow.mid_level}, Phone: {facilityToShow.mid_level_phone}
          </h5>
          <h5 className="fw-light">
            <span className="fw-bold">Social Worker: </span>
            {facilityToShow.social_worker}, Phone:{" "}
            {facilityToShow.social_worker_phone}
          </h5>
        </ModalBody>
        <ModalFooter>
          <button
            className="m-2 btn"
            style={{ border: "1px solid #f7ab48", color: "#f7ab48" }}
            onClick={() => setVisible(false)}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default YourFacilities;
