import React from 'react';

const GlobalFilter = ({ filter, setFilter }) => {
    return ( 
        // <span>
        //     Search:{' '}
        //     <input value={filter || ''} onChange={(e) => setFilter(e.target.value)} />
        // </span>
        <div class="input-group mb-3">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
            <input type="text" value={filter || ''} onChange={(e) => setFilter(e.target.value)} class="form-control" aria-label="Text input" placeholder="Search" />
        </div>
     );
}
 
export default GlobalFilter;