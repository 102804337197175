// Root
import React from 'react';

// External Libraries
import { Link } from 'react-router-dom';

// Components
import { HeaderMain } from '../common/UI/headerMain';
import { Container } from 'reactstrap';

const OnSuccess = (props) => {

    const { user, userData } = props;
            
    //console.log(user);

    return (   
    <React.Fragment>
        <Container className="mt-5 mb-5">
            <HeaderMain title="Care Manager Successfully On Boarded" subTitle=""/>

            <div className="mt-5">
                <h3 className="fw-light">User Details</h3>
                <hr></hr>

                <h4 className="">Full Name: <span className="text-primary">{userData.firstName} {userData.mi} {userData.lastName}</span></h4>
                <h4 className="">Email: <span className="text-primary">{userData.email}</span></h4>
                <h4 className="">Phone: <span className="text-primary">{userData.phone}</span></h4>
            </div>

            <div className="mt-5">
                <h3 className="fw-light">Account Details</h3>
                <hr></hr>

                <h4 className="">Username: <span className="text-primary">{userData.username}</span></h4>
                <h4 className="">Password: <span className="text-primary">{userData.password}</span></h4>
                <h4 className="">Type: <span className="text-primary">Limited</span></h4>
            </div>

            <div className="mt-5">
                <button className="btn btn-md btn-primary mt-5">Add Patients</button>
                <Link to="/home">
                    <button className="btn btn-md btn-danger mt-5 ms-3">Go Home</button>
                </Link>
            </div>
        </Container>
    </React.Fragment> );
}
 
export default OnSuccess;