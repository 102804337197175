import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const getAllProviders = () =>
  api.get("provider").then((res) => res.data);

// GET all medications, pass in patient ID
export const getProviders = (params) =>
  api.post("provider", params).then((res) => res.data);

// Add medication(s), pass medication fields
export const addProvider = (params) =>
  api.put("provider", params).then((res) => res.data);

// Add medication(s), pass medication fields
export const addProviderJoin = (params) =>
  api.post("patient/provider/join", params).then((res) => res.data);

// Delete single medication, pass medication ID
export const deleteProviders = (params) =>
  api.delete("provider", params).then((res) => res.data);