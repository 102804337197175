import React from "react";

import { Card, CardBody, Container } from "reactstrap";

import { HeaderMain } from "./common/UI/headerMain";

const Pod = () => {
  return (
    <div>
      <Container className="d-flex mt-5">
        {/* <h1>Your Pod Team</h1> */}
        <HeaderMain title="Your Pod Team" subTitle="" />
        <div className="ms-auto"></div>
      </Container>
      <Container className="d-flex" style={{ width: "100%" }}>
        <div className="mt-2 mb-2 me-2" style={{ width: "25%" }}>
          <Card className="mb-3">
            <CardBody>
              <React.Fragment>
                <div className="d-flex justify-content-center my-3">
                  <h3></h3>
                </div>
                <div className="mb-4 text-center">
                  <a
                    className="h5 text-decoration-none"
                    style={{ color: "#f7ab48" }}
                    href="#"
                  ></a>
                  {/* <div className="text-center">
                        <i className="fa fa-map-marker me-2"></i>

                      </div> */}
                  <div className="mt-4 text-center pb-1">
                    <ul className="list-inline">
                      <li className="list-inline-item text-center">
                        <h4 className="mb-1">Natalie Schmidt, RN</h4>
                      </li>
                    </ul>
                  </div>

                  <h5 className="fw-bold">Team Lead Details:</h5>
                  <div className="text-center">
                    <i className="mr-1"></i>
                    Email: nschmidt@frontizo.com
                  </div>
                  <div className="text-center">
                    <i className="mr-1"></i>
                    Phone: 248-401-3941
                  </div>
                  <div className="text-center">
                    <i className="mr-1"></i>
                    Location: Chicago IL
                  </div>
                </div>
              </React.Fragment>
              <div className="text-center mb-4">
                <div className="mb-2">
                  <h5 className="fw-light">Role</h5>
                  Team Lead
                </div>
              </div>

              <div className="text-center mb-4">
                <div className="mb-2">
                  <h5 className="fw-light">Chat with Natalie</h5>
                  <a href="https://chat.frontizo.com/direct/nschmidt">
                    Enter Chat
                  </a>{" "}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="m-2" style={{ width: "25%" }}>
          <Card className="mb-3">
            <CardBody>
              <React.Fragment>
                <div className="d-flex justify-content-center my-3">
                  <h3></h3>
                </div>
                <div className="mb-4 text-center">
                  <a
                    className="h5 text-decoration-none"
                    style={{ color: "#f7ab48" }}
                    href="#"
                  ></a>

                  <div className="mt-4 text-center pb-1">
                    <ul className="list-inline">
                      <li className="list-inline-item text-center">
                        <h4 className="mb-1">Kiersten Kerley, RN</h4>
                      </li>
                    </ul>
                  </div>

                  <h5 className="fw-bold">Pod Nurse Details:</h5>
                  <div className="text-center">
                    <i className="mr-1"></i>
                    Email: kkerley@frontizo.com
                  </div>
                  <div className="text-center">
                    <i className="mr-1"></i>
                    Phone: 248-412-3813
                  </div>
                  <div className="text-center">
                    <i className="mr-1"></i>
                    Location: Chicago IL
                  </div>
                </div>
              </React.Fragment>
              <div className="text-center mb-4">
                <div className="mb-2">
                  <h5 className="fw-light">Role</h5>
                  Pod Nurse
                </div>
              </div>

              <div className="text-center mb-4">
                <div className="mb-2">
                  <h5 className="fw-light">Chat with Kiersten</h5>
                  <a href="https://chat.frontizo.com/direct/kkerley">
                    Enter Chat
                  </a>{" "}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="m-2" style={{ width: "25%" }}>
          <Card className="mb-3">
            <CardBody>
              <React.Fragment>
                <div className="d-flex justify-content-center my-3">
                  <h3></h3>
                </div>
                <div className="mb-4 text-center">
                  <a
                    className="h5 text-decoration-none"
                    style={{ color: "#f7ab48" }}
                    href="#"
                  ></a>

                  <div className="mt-4 text-center pb-1">
                    <ul className="list-inline">
                      <li className="list-inline-item text-center">
                        <h4 className="mb-1">Lucy Rivera, MA</h4>
                      </li>
                    </ul>
                  </div>

                  <h5 className="fw-bold">Medical Assistant Details:</h5>
                  <div className="text-center">
                    <i className="mr-1"></i>
                    Email: lrivera@frontizo.com
                  </div>
                  <div className="text-center">
                    <i className="mr-1"></i>
                    Phone: 630-401-3941
                  </div>
                  <div className="text-center">
                    <i className="mr-1"></i>
                    Location: Chicago IL
                  </div>
                </div>
              </React.Fragment>
              <div className="text-center mb-4">
                <div className="mb-2">
                  <h5 className="fw-light">Role</h5>
                  Medical Assistant
                </div>
              </div>

              <div className="text-center mb-4">
                <div className="mb-2">
                  <h5 className="fw-light">Chat with Lucy</h5>
                  <a href="https://chat.frontizo.com/direct/lrivera">
                    Enter Chat
                  </a>{" "}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="ms-2 mt-2 mb-2" style={{ width: "25%" }}>
          <Card className="mb-3">
            <CardBody>
              <React.Fragment>
                <div className="d-flex justify-content-center my-3">
                  <h3></h3>
                </div>
                <div className="mb-4 text-center">
                  <a
                    className="h5 text-decoration-none"
                    style={{ color: "#f7ab48" }}
                    href="#"
                  ></a>

                  <div className="mt-4 text-center pb-1">
                    <ul className="list-inline">
                      <li className="list-inline-item text-center">
                        <h4 className="mb-1">Chelsie Shaaia, CPhT</h4>
                      </li>
                    </ul>
                  </div>

                  <h5 className="fw-bold">Pharm Tech Details:</h5>
                  <div className="text-center">
                    <i className="mr-1"></i>
                    Email: cshaaia@frontizo.com
                  </div>
                  <div className="text-center">
                    <i className="mr-1"></i>
                    Phone: 630-401-3941
                  </div>
                  <div className="text-center">
                    <i className="mr-1"></i>
                    Location: Chicago IL
                  </div>
                </div>
              </React.Fragment>
              <div className="text-center mb-4">
                <div className="mb-2">
                  <h5 className="fw-light">Role</h5>
                  Pharmacy Technician
                </div>
              </div>

              <div className="text-center mb-4">
                <div className="mb-2">
                  <h5 className="fw-light">Chat with Chelsie</h5>
                  <a href="https://chat.frontizo.com/direct/cshaaia">
                    Enter Chat
                  </a>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>
      <Container className="mb-5">
        <div className="d-grid">
          <a
            className="btn btn-block btn-lg mb-2 text-light"
            style={{ backgroundColor: "#107869" }}
            href="https://chat.frontizo.com/channel/Natalies-Pod"
          >
            Pod Chat
          </a>
        </div>
      </Container>
    </div>
  );
};

export default Pod;
