import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, isValid, toDate } from "date-fns";

const RenderDatePicker = ({ name, input, placeholder,input: { value, onChange } }) => {
  return (
    <DatePicker
      className="p-2"
      locale="de"
      placeholderText={placeholder}
      dateFormat="P"
      value={input.value}
      selected={value && isValid(value) ? toDate(value) : null} // needs to be checked if it is valid date
      disabledKeyboardNavigation
      name={name}
      onChange={(date) => {
        // On Change, you should use final-form Field Input prop to change the value
        if (isValid(date)) {
          input.onChange(format(new Date(date), "yyyy-MM-dd"));
        } else {
          input.onChange(null);
        }
      }}
    />
  );
};

export default RenderDatePicker;
