import React from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import GlobalFilter from "./globalFilter";

const ReactTable = (props) => {
  const { columns, data, isLoading, getOnClick, exportCSV, exportPDF } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex } = state;

  return (
    <div>
      <div className="d-flex">
        <div style={{ width: "25%" }} className="mr-auto">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
        <div className="ms-auto">
          {/* <button className="btn btn-outline-primary mt-2 ms-2 mb-2" disabled onClick={() => exportCSV()}>Export to CSV</button>
          <button className="btn btn-outline-primary mt-2 ms-2 mb-2" disabled onClick={() => exportPDF()}>Export to PDF</button> */}
        </div>
      </div>
      <table className="table mt-2 table-striped" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className="fw-bold"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i className="fa fa-sort-desc" />
                      ) : (
                        <i className="fa fa-sort-asc" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} style={{ cursor: "pointer" }}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => getOnClick(row.original)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <span className="mt-5">
        Page{" "}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>{" "}
      </span>
      {/* <span className="m-2">
        Go to page:{"   "}
        <input
        className="outline-primary"
        type="number"
        defaultValue={pageIndex + 1}
        onChange={(e) => {
          const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
          gotoPage(pageNumber);
        }}
        style={{ width: "50px" }}
        ></input>
      </span> */}

      <button
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        style={{ color: "#107869", border: "1px solid #107869" }}
        className="btn m-2"
      >
        {"<<"}
      </button>
      <button
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        style={{ color: "#107869", border: "1px solid #107869" }}
        className="btn m-2"
      >
        Previous
      </button>
      <button
        onClick={() => nextPage()}
        disabled={!canNextPage}
        style={{ color: "#107869", border: "1px solid #107869" }}
        className="btn m-2"
      >
        Next
      </button>
      <button
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
        style={{ color: "#107869", border: "1px solid #107869" }}
        className="btn m-2"
      >
        {">>"}
      </button>

      {isLoading ? (
        <div class="center m-5">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ReactTable;
