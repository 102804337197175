import React from "react";

// External Libraries
import { Container, Card } from "reactstrap";
import { Link } from "react-router-dom";

// Components
import { HeaderMain } from "../common/UI/headerMain";
import PatientOnBoarding from "./patientOnBoarding";

const OnBoarding = () => {
  return (
    <React.Fragment>
      <Container className="mt-5 mb-5">
        <HeaderMain title="Admin Dashboard" subTitle="" />

        {/* DEVELOPMENT IN PROGRESS NEW MENU DESIGN */}
        {/* <div className='row' style={{ width: "100%" }}>
                <div className='col-2'>
                <ul class="nav flex-column">
                    <li className="nav-item">
                        <div style={{ border: "2px solid lightgrey", borderRadius: "5px" }}>
                            <a className="nav-link active" href="#"><i className='fa fa-user me-2'></i>Patient Onboarding</a>
                        </div>
                    </li>
                    <li className="nav-item mt-2" >
                        <div>
                            <button className="btn" href="#"><i className='fa fa-user me-2'></i> Providers</button>
                        </div>
                    </li>
                    <li className="nav-item mt-2">
                        <div>
                            <button className="btn" href="#"><i className='fa fa-building me-2'></i> Facilities</button>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="#">Care Managers</a>
                    </li>
                </ul>
                </div>
                <div className='col-10' style={{ border: "1px solid lightgrey", borderRadius: "5px" }}>
                    <PatientOnBoarding />
                </div>
            </div> */}

        <div className="mt-5">
          <h3 className="fw-light">Menu</h3>
          <hr></hr>
          <div className="d-flex">
            <Link
              to="/admin/onboard/patient"
              className="me-3"
              style={{ textDecoration: "none" }}
            >
              <button
                className="btn btn-lg text-light ms-auto"
                style={{ backgroundColor: "#107869", width: 256 }}
              >
                Add Patient
              </button>
            </Link>
            <Link
              to="/admin/onboard/provider"
              className="me-3"
              style={{ textDecoration: "none" }}
            >
              <button
                className="btn btn-lg text-light ms-auto"
                style={{ backgroundColor: "#107869", width: 256 }}
              >
                Providers
              </button>
            </Link>
            <Link
              to="/admin/onboard/facility"
              className="me-3"
              style={{ textDecoration: "none" }}
            >
              <button
                className="btn btn-lg text-light ms-auto"
                style={{ backgroundColor: "#107869", width: 256 }}
              >
                Facility
              </button>
            </Link>
            {/* <Link
              to="/admin/onboard/caremanager"
              className="me-3"
              style={{ textDecoration: "none" }}
            >
              <Card className="p-5">
                <h3 className="fw-light">Care Manager</h3>
              </Card>
            </Link> */}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default OnBoarding;
