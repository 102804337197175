// Root
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";

// External Libraries
import { Card, CardBody, CardTitle, CardFooter, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// Components (UI Folder)
import { Progress } from "./progress";

const NotifHomeCard
 = (props) => {
  const { link, title, data, type, descriptor } = props;

  const [list, setList] = useState([]);

  useEffect(() => {
    console.log(data);
    let tmp = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].status == "notStarted") tmp.push(data[i]);
    }
    setList(tmp);
  }, [])

  return (
    <Link
      to={link}
      state={{ data: data }}
      style={{ cursor: "pointer", textDecoration: "none" }}
    >
      <Card className="mb-3">
        <CardBody>
          <CardTitle tag="h5" className="mb-4 fw-bold text-dark">
            {title}
          </CardTitle>

          <Row>
            <Col sm={4}>
              <h2 className={`text-primary`}>{list.length}</h2>
              <div className={`mb-3 text-primary`}>{type}</div>
            </Col>
          </Row>

          <Progress multi className="mb-2" style={{ height: "5px" }}>
            <Progress bar value="25" />
            <Progress bar color="success" value="30" />
            <Progress bar color="secondary" value="45" />
          </Progress>
        </CardBody>

        <CardFooter className="small text-dark" style={{ backgroundColor: "#f4fcf2" }}>
          <i className="fa fa-fw fa-info-circle mr-2"></i>
          {descriptor}
        </CardFooter>
      </Card>
    </Link>
  );
};

NotifHomeCard
.propTypes = {
  link: propTypes.string,
  title: propTypes.string,
  value: propTypes.number,
  type: propTypes.string,
  descriptor: propTypes.string,
};

NotifHomeCard.defaultProps = {
  link: "/",
  title: "Waiting...",
  value: "0",
  type: "Waiting...",
  descriptor: "Waiting...",
};

export default NotifHomeCard;
