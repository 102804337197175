import React, { useState } from 'react';

// External Libraries
import { Form, Field } from "react-final-form";
import { Form as FormReact, Container, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import RenderDatePicker from "../common/UI/RenderDatePicker";
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// Components
import { HeaderMain } from "../common/UI/headerMain";

// Services
import * as api from "../../services";
import * as visitApi from "../../service/visitService";
import * as medApi from "../../service/medService";
import * as providerApi from "../../service/providerService";

const PatientOnBoarding = () => {

    const [isLoading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [added, setAdded] = useState({});

    const navigate = useNavigate();

    const { mutate: cognitoPatient } = useMutation(api.cognitoPatientLogin, {
        onSuccess: (data) => {
            toast.success("Patient Login Created");
            // navigate('/admin/onboard');
        },
        onError: () => {
            toast.error("Patient Failed to Create Login")
        }
    });

    const { mutate: addPatient } = useMutation(api.addPatient, {
        onSuccess: (data) => {
            console.log(data);
            toast.success("Patient Added to Database");
            addCognitoPool(data);
            setVisible(true);
            setAdded(data);
        },
        onError: () => {
            toast.error("Patient Failed to Add")
        }
    });

    const addCognitoPool = (data) => {        
        let cognitoObj = {};
        cognitoObj.email = data.email;
        cognitoObj.password = "Frontizo2023!";
        cognitoObj.ptID = data.id;

        console.log(cognitoObj);
        cognitoPatient(cognitoObj);
    }

    const careUsers = useQuery(["users"], () => api.getCareUsers({}), {
        onSuccess: (data) => {
        //   console.log(data);
        },
      });

    const facilities = useQuery(["facilities"], () => api.getFacilities({}), {
        onSuccess: (data) => {
        //   console.log(data);
        },
      });

    const providers = useQuery(
        ["provs"],
        () => providerApi.getAllProviders(),
        {
          onSuccess: (data) => {
            // console.log(data);
          },
        }
      );

    const dateTime = new Date();

    const onAddHandler = (data) => {
        addPatient(data);
    };

    const goHomeHandler = () => {
        navigate('/');
    };

    const goPatientListHandler = () => {
        navigate('/patients');
    };

    const addAnotherHandler = () => {
        setVisible(false);
    };

    return (
        <React.Fragment>
            <Container className="mt-5 mb-5">
                <HeaderMain
                    title="Patient Onboarding"
                    subTitle="Please enter all patient details to the best of your knowledge."
                />

                <div>
                    <Form
                        onSubmit={(dataObj) => {
                            dataObj.dob = dataObj.dob_year.toString() + "-" + dataObj.dob_month.toString() + "-" + dataObj.dob_day.toString();
                            
                            if("managed_by" in dataObj) {
                                if("provider" in dataObj) {
                                    if("facility" in dataObj) {
                                        onAddHandler(dataObj);
                                    } else
                                        toast.error("Select a Facility!")
                                } else
                                    toast.error("Select a Physiatrist!")
                            } else
                                toast.error("Select a Care Manager!")
                            
                        }}
                        mutators={{
                            // potentially other mutators could be merged here
                            ...arrayMutators,
                        }}
                    >
                    {({ handleSubmit, form, submitting, pristine }) => (
                        <FormReact
                            onSubmit={handleSubmit}
                            className=""
                            style={{ width: "90%", height: "100%" }}
                        >
                        <h5 className="fw-light mt-4">Personal Details</h5>

                        {careUsers.isLoading === true ? <></> :
                            <Field name="managed_by">
                                {({ input }) => (
                                <select
                                    className="form-control mt-3 p-2"
                                    name={input.name}
                                    style={{ width: "100%" }}
                                    onChange={(value) => input.onChange(value)}
                                >
                                    <option key="none" value="none">
                                    Choose Patients Care Manager
                                    </option>
                                    {careUsers.data.map((ea) => (
                                    <option key={ea.id} value={ea.id}>
                                        {ea.first_name} {ea.last_name}
                                    </option>
                                    ))}
                                </select>
                                )}
                            </Field>
                        }

                        {providers.isLoading === true ? <></> :
                            <Field name="provider">
                                {({ input }) => (
                                <select
                                    className="form-control mt-3 p-2"
                                    name={input.name}
                                    style={{ width: "100%" }}
                                    onChange={(value) => input.onChange(value)}
                                >
                                    <option key="none" value="none">
                                    Choose Patients Physiatrist
                                    </option>
                                    {providers.data.map((ea) => (
                                    <option key={ea.id} value={ea.id}>
                                        {ea.first_name} {ea.last_name}
                                    </option>
                                    ))}
                                </select>
                                )}
                            </Field>
                        }

                        <div className='d-flex mt-3' style={{ width: "100%" }}>
                            <div className='' style={{ width: "40%" }}>
                                <h5 className='fw-light'>First Name</h5>
                                <Field name="first_name">
                                    {({ input }) => (
                                    <input
                                        className="p-2"
                                        style={{ width: "100%", border: "1px lightgrey solid", borderRadius: "10px" }}
                                        placeholder=""
                                        type="text"
                                        required
                                        {...input}
                                    />
                                    )}
                                </Field>
                            </div>
                            <div className='ms-3' style={{ width: "40%" }}>
                                <h5 className='fw-light'>Last Name</h5>
                                <Field name="last_name">
                                    {({ input }) => (
                                    <input
                                        className="p-2"
                                        style={{ width: "100%", border: "1px lightgrey solid", borderRadius: "10px" }}
                                        placeholder=""
                                        type="text"
                                        required
                                        {...input}
                                    />
                                    )}
                                </Field>
                            </div>
                            <div className='ms-3' style={{ width: "10%" }}>
                                <h5 className='fw-light'>DOB Day</h5>
                                <Field name="dob_day">
                                    {({ input }) => (
                                    <input
                                        className="p-2"
                                        style={{ width: "100%", border: "1px lightgrey solid", borderRadius: "10px" }}
                                        placeholder="01"
                                        type="number"
                                        required
                                        {...input}
                                    />
                                    )}
                                </Field>
                            </div>
                            <div className='ms-3' style={{ width: "10%" }}>
                                <h5 className='fw-light'>Month</h5>
                                <Field name="dob_month">
                                    {({ input }) => (
                                    <input
                                        className="p-2"
                                        style={{ width: "100%", border: "1px lightgrey solid", borderRadius: "10px" }}
                                        placeholder="01"
                                        type="number"
                                        required
                                        {...input}
                                    />
                                    )}
                                </Field>
                            </div>
                            <div className='ms-3' style={{ width: "20%" }}>
                                <h5 className='fw-light'>Year</h5>
                                <Field name="dob_year">
                                    {({ input }) => (
                                    <input
                                        className="p-2"
                                        style={{ width: "100%", border: "1px lightgrey solid", borderRadius: "10px" }}
                                        placeholder="1940"
                                        type="number"
                                        required
                                        {...input}
                                    />
                                    )}
                                </Field>
                            </div>
                            {/* <div className='ms-3' style={{ width: "20%" }}>
                                <h5 className='fw-light'>Date of Birth</h5>
                                <span className="mt-3"
                                        style={{ width: "100%"}}>
                                    <Field
                                        name="dob"
                                        component={RenderDatePicker}
                                        placeholder="2023-01-01"
                                    />
                                </span>
                            </div> */}
                        </div>

                        <div className='d-flex mt-4' style={{ width: "100%" }}>
                                <div className="me-3" style={{ width: "40%"}}>
                                    <h5 className='fw-light'>Address</h5>
                                    <Field name="address">
                                    {({ input }) => (
                                        <input
                                        className="p-2"
                                        style={{ width: "100%", border: "1px lightgrey solid", borderRadius: "10px" }}
                                        placeholder=""
                                        type="text"
                                        required
                                        {...input}
                                        />
                                    )}
                                    </Field>
                                </div>

                                <div className="me-3" style={{ width: "30%"}}>
                                    <h5 className='fw-light'>City</h5>
                                    <Field name="city">
                                    {({ input }) => (
                                        <input
                                        className="p-2"
                                        style={{ width: "100%", border: "1px lightgrey solid", borderRadius: "10px" }}
                                        placeholder=""
                                        type="text"
                                        required
                                        {...input}
                                        />
                                    )}
                                    </Field>
                                </div>

                                <div className="me-3" style={{ width: "20%"}}>
                                    <h5 className='fw-light'>State</h5>
                                    <Field name="state">
                                    {({ input }) => (
                                        <input
                                        className="p-2"
                                        style={{ width: "100%", border: "1px lightgrey solid", borderRadius: "10px" }}
                                        placeholder=""
                                        type="text"
                                        required
                                        {...input}
                                        />
                                    )}
                                    </Field>
                                </div>
                                
                                <div style={{ width: "10%"}}>
                                    <h5 className='fw-light'>Zip</h5>
                                    <Field name="zip">
                                    {({ input }) => (
                                        <input
                                        className="p-2"
                                        style={{ width: "100%", border: "1px lightgrey solid", borderRadius: "10px" }}
                                        placeholder=""
                                        type="text"
                                        required
                                        {...input}
                                        />
                                    )}
                                    </Field>
                                </div>
                        </div>

                        <div className='d-flex mt-3' style={{ width: "100%" }}>
                            <div className='' style={{ width: "50%" }}>
                                <h5 className='fw-light'>Personal Email</h5>
                                <Field name="email">
                                    {({ input }) => (
                                    <input
                                        className="p-2"
                                        style={{ width: "100%", border: "1px lightgrey solid", borderRadius: "10px" }}
                                        placeholder=""
                                        required
                                        type="text"
                                        {...input}
                                    />
                                    )}
                                </Field>
                            </div>
                            <div className='ms-3' style={{ width: "50%" }}>
                                <h5 className='fw-light'>Personal Phone</h5>
                                <Field name="phone">
                                    {({ input }) => (
                                    <input
                                        className="p-2"
                                        style={{ width: "100%", border: "1px lightgrey solid", borderRadius: "10px" }}
                                        placeholder=""
                                        required
                                        type="text"
                                        {...input}
                                    />
                                    )}
                                </Field>
                            </div>
                        </div>
                        
                        <h5 className="fw-light mt-4">Facility Details</h5>

                        {facilities.isLoading === true ? <></> :
                            <Field name="facility">
                                {({ input }) => (
                                <select
                                    className="form-control mt-3 p-2"
                                    name={input.name}
                                    style={{ width: "100%" }}
                                    onChange={(value) => input.onChange(value)}
                                >
                                    <option key="none" value="none">
                                    Choose a Skilled Nursing Facility
                                    </option>
                                    {facilities.data.map((ea) => (
                                    <option key={ea.id} value={ea.id}>
                                        {ea.name}
                                    </option>
                                    ))}
                                </select>
                                )}
                            </Field>
                        }

                        <div className='d-flex mt-3' style={{ width: "100%" }}>
                            <div style={{ width: "20%" }}>
                                <h5 className='fw-light'>Discharge Date</h5>
                                <span className=""
                                    style={{ width: "100%"}}>
                                        <Field
                                            name="dc_date"
                                            component={RenderDatePicker}
                                            placeholder=""
                                        />
                                </span>
                            </div>
                            <div className='ms-3' style={{ width: "30%" }}>
                                <h5 className='fw-light'>Hospital DC ICD10 Code</h5>
                                <Field name="dc_diagnosis">
                                    {({ input }) => (
                                    <input
                                        className="p-2"
                                        style={{ width: "100%", border: "1px lightgrey solid", borderRadius: "10px" }}
                                        placeholder=""
                                        type="text"
                                        {...input}
                                    />
                                    )}
                                </Field>
                            </div>
                            <div className='ms-3' style={{ width: "30%" }}>
                                <h5 className='fw-light'>SNF Admit ICD10 Code</h5>
                                <Field name="admit_diagnosis">
                                    {({ input }) => (
                                    <input
                                        className="p-2"
                                        style={{ width: "100%", border: "1px lightgrey solid", borderRadius: "10px" }}
                                        placeholder=""
                                        type="text"
                                        {...input}
                                    />
                                    )}
                                </Field>
                            </div>
                            <div className='ms-3' style={{ width: "20%" }}>
                                <h5 className='fw-light'>Status Code</h5>
                                <Field name="status_code">
                                    {({ input }) => (
                                    <input
                                        className="p-2"
                                        style={{ width: "100%", border: "1px lightgrey solid", borderRadius: "10px" }}
                                        placeholder=""
                                        type="text"
                                        {...input}
                                    />
                                    )}
                                </Field>
                            </div>
                        </div>

                        <div>
                            {isLoading == true ? (
                            <div
                                class="mt-5 spinner-border text-primary"
                                role="status"
                                style={{ width: "3rem", height: "3rem" }}
                            >
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            ) : (
                            <div>
                                <button
                                className="btn text-light btn-lg mt-5"
                                style={{ backgroundColor: "#107869", width: 256 }}
                                type="submit"
                                disabled={submitting || pristine}
                                >
                                Add Patient
                                </button>

                                <button
                                className="btn btn-lg text-light ms-3 mt-5"
                                style={{ backgroundColor: "#f7ab48", width: 256 }}
                                type="button"
                                disabled={submitting || pristine}
                                onClick={() => form.reset()}
                                >
                                Reset Form
                                </button>
                            </div>
                            )}
                        </div>
                        </FormReact>
                    )}
                    </Form>
                </div>

                <Modal isOpen={visible} size="lg" centered>
                    <ModalHeader>
                        <div className='d-flex'>
                            <h3 className='text-success'>{added.first_name} {added.last_name} Just Added</h3>
                            {/* <button
                                className="btn btn-md ms-auto"
                                type="button"
                                onClick={() => setVisible(false)}
                                >
                                <i className='fa fa-close fa-2x text-danger'></i>
                            </button> */}
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <h5 className='fw-light'>Patient Name: {added.first_name} {added.last_name}</h5>
                        <h5 className='fw-light'>Date of Birth: {added.dob}</h5>
                        <h5 className='fw-light'>Address: {added.address} {added.city} {added.state} {added.zip}</h5>
                        <h5 className='fw-light'>Email: {added.email}</h5>
                        <h5 className='fw-light'>Phone: {added.phone}</h5>
                        <h5 className='fw-light'>DC Date: {added.dc_date}</h5>
                        <p className='fst-italic'>Additional details not shown...</p>
                        <hr></hr>
                        <h3>Login Credentials</h3>
                        <h5 className='fw-light'>Username: {added.email}</h5>
                        <h5 className='fw-light'>Password: Frontizo2023!</h5>
                    </ModalBody>
                    <ModalFooter>
                        <div className='d-flex'>
                            <button className='m-2 btn btn-outline-primary' onClick={goHomeHandler}>
                                
                                Go Home
                            </button>
                            <button className='m-2 btn btn-outline-primary' disabled>
                                Go to Newly Added Patient
                            </button>
                            <button className='m-2 btn btn-outline-primary' onClick={addAnotherHandler}>
                                Add Another Patient
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </Container>
        </React.Fragment>
    );
}

export default PatientOnBoarding;