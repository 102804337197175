export const COLUMNS = [
  {
    Header: "Full Name",
    accessor: (d) => `${d.first_name} ${d.last_name}`,
  },
  {
    Header: "DOB",
    accessor: "dob",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Facility",
    accessor: (d) => `${d.facility.name}`,
  },
  {
    Header: "Care Manager",
    accessor: (d) => `${d.managed_by.first_name} R.N.`,
  },
  {
    Header: "Discharge Date",
    accessor: "dc_date",
  },
  {
    Header: "Since Discharge",
    Cell: row => {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      let dcDate = new Date(row.row.original.dc_date);
      let today = new Date();

      const diffDays = Math.round(Math.abs((dcDate - today) / oneDay));

      return diffDays + " Days";
    },
  },
];
