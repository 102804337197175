import React from "react";

// Third party Libraries
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form as FormReact,
} from "reactstrap";
import _ from "lodash";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import RenderDatePicker from "../common/UI/RenderDatePicker";

// Services
import * as api from "../../services";
import * as providerAPI from "../../service/providerService";

// Components
import { HeaderMain } from "./UI/headerMain";
import { useState } from "react";
import ReactTable from "../common/UI/reactTable";

import { NOTIFSCOLUMNS } from "../reacttable/patientAlertsColumns";
import Medications from "./patientProfile/medications";
import Visits from "./patientProfile/visits";
import PatientNotifications from "./patientProfile/notifications";
import LoadingSpinner from "./UI/loadingSpinner";

const PatientProfile = () => {
  const navigate = useNavigate();
  const [notifs, setNotifs] = useState([]);

  const [visible, setVisible] = useState(false);

  const location = useLocation();
  const id = location.state.id;

  const alerts = useQuery(["patientAlerts"], () => api.getAllAlerts(), {
    onSuccess: (data) => {
      let obj = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].patient.id == id) obj.push(data[i]);
      }

      setNotifs(obj);
    },
  });

  const { data, isLoading } = useQuery(
    ["patient"],
    () => api.getPatientInstance({ id: id }),
    {
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  const queryClient = useQueryClient();

  const { mutate: updateProfile } = useMutation(api.updatePatientProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries("patient");
      toast.success("Patient Profile Updated");
    },
    onError: () => {
      toast.error("Patient Profile Failed to Update");
    },
  });

  const providers = useQuery(["providerList"], providerAPI.getAllProviders, {
    onSuccess: (data) => {},
  });

  const onRowClick = (params) => {
    navigate(`/notification`, {
      state: { data: params },
    });
  };

  const onCreateNotification = () => {
    navigate(`/createnotification`, {
      state: { data: data },
    });
  };

  const onUpdateHandler = (params) => {
    params.id = id;
    console.log(params);

    updateProfile(params);
    setVisible(false);
  };

  const typeSwitch = (type) => {
    switch (type) {
      case "med_help":
        return "Medication Help";
      case "appt_help":
        return "Appointment Help";
      case "feelsick":
        return "I Feel Sick";
      case "homesurvey":
        return "Home Survey";
      case "response":
        return "Responding";
      default:
        break;
    }
  };

  const statusSwitch = (param) => {
    switch (param) {
      case "completed":
        return "Completed";
      case "pending":
        return "In Progress";
      case "notStarted":
        return "Not Started";
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {isLoading == true ? (
        <Container className="mt-5 mb-5">
          <LoadingSpinner />
        </Container>
      ) : (
        <div
          className=""
          style={{
            paddingLeft: "5%",
            paddingRight: "5%",
            marginBottom: "5%",
          }}
        >
          <Container className="ms-5 me-5" style={{}}>
            <HeaderMain
              title={`Patient View for ${data.first_name} ${data.last_name}`}
              subTitle=""
            />
          </Container>

          <div className="d-flex m-5" style={{}}>
            <div className="" style={{ width: "45%", height: 700 }}>
              <Card className="mb-3">
                <CardBody>
                  <React.Fragment>
                    <div className="d-flex justify-content-center my-3">
                      <h3>
                        {data.first_name} {data.last_name}
                      </h3>

                      <button
                        className="btn btn-sm h1 ms-5"
                        onClick={() => setVisible(true)}
                        style={{
                          color: "#107869",
                          border: "1px solid #107869",
                        }}
                      >
                        Edit
                      </button>
                    </div>
                    <div className="mb-4 text-center">
                      <a
                        className="h5 text-decoration-none"
                        style={{ color: "#f7ab48" }}
                        href="#"
                      >
                        Date of SNF Discharge: {data.dc_date}
                      </a>
                      <div className="text-center">
                        <i className="fa fa-map-marker me-2"></i>
                        Admission Facility: {data.facility.name}
                      </div>
                      <div className="mt-4 text-center pb-1">
                        <ul className="list-inline">
                          <li className="list-inline-item text-center">
                            <h4 className="mb-1">
                              {data.managed_by.first_name}{" "}
                              {data.managed_by.last_name}
                            </h4>
                            <span>Care Coordinator</span>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-2 mb-2 text-center pb-1">
                        <li className="list-inline-item text-center">
                          <h4 className="mb-1">
                            {data.providers[0].first_name}{" "}
                            {data.providers[0].last_name}
                          </h4>
                          <span>Provider</span>
                        </li>
                      </div>
                      <h5 className="fw-bold">Patient Details:</h5>
                      <div className="text-center">
                        <i className="mr-1"></i>
                        Email: {data.email}
                      </div>
                      <div className="text-center">
                        <i className="mr-1"></i>
                        Phone: {data.phone}
                      </div>
                      <div className="text-center">
                        <i className="mr-1"></i>
                        Address: {data.address}, {data.city} {data.state},{" "}
                        {data.zip}
                      </div>
                      <div className="text-center">
                        <i className="mr-1"></i>
                        DOB: {data.dob}
                      </div>
                    </div>
                  </React.Fragment>
                  <div className="text-center mb-4">
                    <div className="mb-2">
                      <h5 className="fw-light">Hospital Discharge Diagnosis</h5>
                      {data.dc_diagnosis}
                    </div>
                  </div>

                  <div className="text-center mb-4">
                    <div className="mb-2">
                      <h5 className="fw-light">SNF Admit Diagnosis</h5>
                      {data.admit_diagnosis}
                    </div>
                  </div>

                  <div className="text-center mb-4">
                    <div className="mb-2">
                      <h5 className="fw-light">Code Status</h5>
                      <p>{data.status_code}</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <PatientNotifications
              data={notifs}
              onCreateNotification={onCreateNotification}
              NOTIFSCOLUMNS={NOTIFSCOLUMNS}
              onRowClick={onRowClick}
              patient={data}
            />
          </div>

          <div className="m-5" style={{}}>
            <Medications id={id} providers={providers.data} />
          </div>

          <div className="m-5" style={{}}>
            <Visits id={id} providers={providers.data} />
          </div>

          <Modal isOpen={visible} size="lg" centered>
            <ModalHeader>
              <div className="d-flex">
                <h3 className="" style={{ color: "#107869" }}>
                  Edit {data.first_name} {data.last_name}'s Profile
                </h3>
              </div>
            </ModalHeader>
            <ModalBody>
              <div>
                <Form
                  onSubmit={(dataObj) => {
                    if ("dob_year" in dataObj) {
                      dataObj.dob =
                        dataObj.dob_year.toString() +
                        "-" +
                        dataObj.dob_month.toString() +
                        "-" +
                        dataObj.dob_day.toString();
                    }

                    onUpdateHandler(dataObj);
                  }}
                  mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators,
                  }}
                >
                  {({ handleSubmit, form, submitting, pristine }) => (
                    <FormReact
                      onSubmit={handleSubmit}
                      className=""
                      style={{ height: "100%" }}
                    >
                      <h5 className="fw-light mt-4">Personal Details</h5>

                      <div className="d-flex mt-3" style={{ width: "100%" }}>
                        <div className="" style={{ width: "40%" }}>
                          <h5 className="fw-light">First Name</h5>
                          <Field name="first_name">
                            {({ input }) => (
                              <input
                                className="p-2"
                                style={{
                                  width: "100%",
                                  border: "1px lightgrey solid",
                                  borderRadius: "10px",
                                }}
                                placeholder=""
                                type="text"
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="ms-3" style={{ width: "40%" }}>
                          <h5 className="fw-light">Last Name</h5>
                          <Field name="last_name">
                            {({ input }) => (
                              <input
                                className="p-2"
                                style={{
                                  width: "100%",
                                  border: "1px lightgrey solid",
                                  borderRadius: "10px",
                                }}
                                placeholder=""
                                type="text"
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="ms-3" style={{ width: "10%" }}>
                          <h5 className="fw-light">DOB</h5>
                          <Field name="dob_day">
                            {({ input }) => (
                              <input
                                className="p-2"
                                style={{
                                  width: "100%",
                                  border: "1px lightgrey solid",
                                  borderRadius: "10px",
                                }}
                                placeholder="01"
                                type="number"
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="ms-3" style={{ width: "10%" }}>
                          <h5 className="fw-light">Month</h5>
                          <Field name="dob_month">
                            {({ input }) => (
                              <input
                                className="p-2"
                                style={{
                                  width: "100%",
                                  border: "1px lightgrey solid",
                                  borderRadius: "10px",
                                }}
                                placeholder="01"
                                type="number"
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="ms-3" style={{ width: "20%" }}>
                          <h5 className="fw-light">Year</h5>
                          <Field name="dob_year">
                            {({ input }) => (
                              <input
                                className="p-2"
                                style={{
                                  width: "100%",
                                  border: "1px lightgrey solid",
                                  borderRadius: "10px",
                                }}
                                placeholder="1940"
                                type="number"
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="d-flex mt-4" style={{ width: "100%" }}>
                        <div className="me-3" style={{ width: "40%" }}>
                          <h5 className="fw-light">Address</h5>
                          <Field name="address">
                            {({ input }) => (
                              <input
                                className="p-2"
                                style={{
                                  width: "100%",
                                  border: "1px lightgrey solid",
                                  borderRadius: "10px",
                                }}
                                placeholder=""
                                type="text"
                                {...input}
                              />
                            )}
                          </Field>
                        </div>

                        <div className="me-3" style={{ width: "30%" }}>
                          <h5 className="fw-light">City</h5>
                          <Field name="city">
                            {({ input }) => (
                              <input
                                className="p-2"
                                style={{
                                  width: "100%",
                                  border: "1px lightgrey solid",
                                  borderRadius: "10px",
                                }}
                                placeholder=""
                                type="text"
                                {...input}
                              />
                            )}
                          </Field>
                        </div>

                        <div className="me-3" style={{ width: "20%" }}>
                          <h5 className="fw-light">State</h5>
                          <Field name="state">
                            {({ input }) => (
                              <input
                                className="p-2"
                                style={{
                                  width: "100%",
                                  border: "1px lightgrey solid",
                                  borderRadius: "10px",
                                }}
                                placeholder=""
                                type="text"
                                {...input}
                              />
                            )}
                          </Field>
                        </div>

                        <div style={{ width: "10%" }}>
                          <h5 className="fw-light">Zip</h5>
                          <Field name="zip">
                            {({ input }) => (
                              <input
                                className="p-2"
                                style={{
                                  width: "100%",
                                  border: "1px lightgrey solid",
                                  borderRadius: "10px",
                                }}
                                placeholder=""
                                type="text"
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="d-flex mt-3" style={{ width: "100%" }}>
                        <div className="" style={{ width: "50%" }}>
                          <h5 className="fw-light">Personal Email</h5>
                          <Field name="email">
                            {({ input }) => (
                              <input
                                className="p-2"
                                style={{
                                  width: "100%",
                                  border: "1px lightgrey solid",
                                  borderRadius: "10px",
                                }}
                                placeholder=""
                                type="text"
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="ms-3" style={{ width: "50%" }}>
                          <h5 className="fw-light">Personal Phone</h5>
                          <Field name="phone">
                            {({ input }) => (
                              <input
                                className="p-2"
                                style={{
                                  width: "100%",
                                  border: "1px lightgrey solid",
                                  borderRadius: "10px",
                                }}
                                placeholder=""
                                type="text"
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                      </div>

                      <h5 className="fw-light mt-4">Facility Details</h5>

                      <div className="d-flex mt-3" style={{ width: "100%" }}>
                        <div className="" style={{ width: "34%" }}>
                          <h5 className="fw-light">Hospital DC ICD10 Code</h5>
                          <Field name="dc_diagnosis">
                            {({ input }) => (
                              <input
                                className="p-2"
                                style={{
                                  width: "100%",
                                  border: "1px lightgrey solid",
                                  borderRadius: "10px",
                                }}
                                placeholder=""
                                type="text"
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="ms-3" style={{ width: "33%" }}>
                          <h5 className="fw-light">SNF Admit ICD10 Code</h5>
                          <Field name="admit_diagnosis">
                            {({ input }) => (
                              <input
                                className="p-2"
                                style={{
                                  width: "100%",
                                  border: "1px lightgrey solid",
                                  borderRadius: "10px",
                                }}
                                placeholder=""
                                type="text"
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="ms-3" style={{ width: "33%" }}>
                          <h5 className="fw-light">Status Code</h5>
                          <Field name="status_code">
                            {({ input }) => (
                              <input
                                className="p-2"
                                style={{
                                  width: "100%",
                                  border: "1px lightgrey solid",
                                  borderRadius: "10px",
                                }}
                                placeholder=""
                                type="text"
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                      </div>

                      <div>
                        {isLoading == true ? (
                          <div
                            class="mt-5 spinner-border text-primary"
                            role="status"
                            style={{ width: "3rem", height: "3rem" }}
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <div>
                            <button
                              className="btn text-light btn-lg mt-5"
                              style={{ backgroundColor: "#107869", width: 256 }}
                              type="submit"
                              disabled={submitting || pristine}
                            >
                              Update Patient
                            </button>

                            <button
                              className="btn btn-lg text-light ms-3 mt-5"
                              style={{ backgroundColor: "#f7ab48", width: 256 }}
                              type="button"
                              disabled={submitting || pristine}
                              onClick={() => form.reset()}
                            >
                              Reset Form
                            </button>
                          </div>
                        )}
                      </div>
                    </FormReact>
                  )}
                </Form>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                className="m-2 btn"
                style={{ border: "1px solid #f7ab48", color: "#f7ab48" }}
                onClick={() => setVisible(false)}
              >
                Close
              </button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </React.Fragment>
  );
};

export default PatientProfile;
