// Root
import React from "react";

// External Libraries
import { Container, Row, Col } from "reactstrap";
import { Auth } from "aws-amplify";
import { useQuery } from "@tanstack/react-query";

// Services
import * as api from "../services";

// Components
import HomeCards from "./common/UI/homeCards";
import { HeaderMain } from "./common/UI/headerMain";
import NotifHomeCard from "./common/UI/notifHomeCard";
import BaseHomeCard from "./common/UI/baseHomeCard";
import LoadingSpinner from "./common/UI/loadingSpinner";

const Home = () => {
  const user = useQuery(
    ["user"],
    () => api.getUser({ id: Auth.user.attributes["custom:id"] }),
    {
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  const patients = useQuery(
    ["patients"],
    () => api.getPatients({ id: Auth.user.attributes["custom:id"] }),
    {
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  const alerts = useQuery(
    ["alerts"],
    () => api.getAlerts({ id: Auth.user.attributes["custom:id"] }),
    {
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  return (
    <React.Fragment>
      {patients.isLoading == true ? (
        <Container className="mt-5 mb-5">
          <LoadingSpinner />
        </Container>
      ) : (
        <>
          {alerts.isLoading == true ? (
            <Container className="mt-5 mb-5">
              <LoadingSpinner />
            </Container>
          ) : (
            <section>
              {/* Header & Sub Header*/}
              <Container className="mt-5 mb-5">
                <HeaderMain
                  title={`Welcome, ${user.data.first_name} ${user.data.last_name}`}
                  subTitle="Let's see what's going on today!"
                />
              </Container>

              {/* 4 Cards */}
              <Container>
                {/* Top Two - Patients & Notifications */}
                <Row>
                  <Col lg={6}>
                    <HomeCards
                      link="/patients"
                      title="Patient List"
                      data={patients.data}
                      type="Patients"
                      descriptor="Here are the people counting on you."
                    />
                  </Col>

                  <Col lg={6}>
                    <NotifHomeCard
                      link="/notifications"
                      title="Your Notifications"
                      data={alerts.data}
                      type="Pending"
                      descriptor="Here are the patients requiring your attention right away."
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <BaseHomeCard
                      link="/pod"
                      title="Your pod"
                      data={4}
                      type="People"
                      descriptor="Here are the people in your pod."
                    />
                  </Col>

                  <Col lg={6}>
                    <BaseHomeCard
                      link="/facility"
                      title="Your Facilities"
                      data={4}
                      type="Facilities"
                      descriptor="Here are your assigned skilled nursing facilities."
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default Home;
