import React from "react";
import { useState } from "react";

// Third Party Libraries
import {
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Card,
  CardBody,
  CardHeader,
  Modal,
} from "reactstrap";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { Form, Field } from "react-final-form";
import { Form as FormReact } from "reactstrap";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

// Components
import ReactTable from "../UI/reactTable";

// Temp
import MicRecorder from "mic-recorder-to-mp3";

// CSS
import "audio-react-recorder/dist/index.css";

// Services
import * as api from "../../../services";

// Headers
import { COLUMNS } from "../../reacttable/careAlertColumn";

const Mp3Recorder = new MicRecorder({ bitRate: 64 });

const PatientNotifications = ({
  data,
  patient,
  onRowClick,
  NOTIFSCOLUMNS,
  onCreateNotification,
}) => {
  const [activeTab, setActiveTab] = useState(1);

  const [careAlerts, setCareAlerts] = useState([]);

  const [recordState, setRecordState] = useState(null);
  const [audioData, setAudioData] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState("");
  const [options, setOptions] = useState([
    "Medication Reminder",
    "Appointment Reminder",
    "Changes to Care Plan",
    "Care Manager Touchbase",
    "Responding to Patient Request",
  ]);
  const [isOpen, setOpen] = useState(false);
  const [modalData, setModalData] = useState({
    patient: {
      first_name: "placeholder",
      last_name: "placeholder",
    },
  });

  const typeSwitch = (param) => {
    switch (param) {
      case "med_help":
        return "Medication Help";
      case "appt_help":
        return "Appointment Help";
      case "feelsick":
        return "I Feel Sick";
      case "homesurvey":
        return "Home Survey";
      case "response":
        return "Response";
      case "other_med":
        return "Other";
      case "other_appt":
        return "Other";
      default:
        break;
    }
  };

  const descriptorSwitch = (param, name) => {
    switch (param) {
      case "med_help":
        return `${name} is having some trouble with their medications please take a look at their medications and contact them accordingly.`;
      case "appt_help":
        return `${name} is having trouble with their appointments please take a look at their appointments and contact them accordingly.`;
      case "feelsick":
        return `${name} is feeling sick please take a look at their response and contact them accordingly.`;
      case "homesurvey":
        return `${name} Just got Home`;
      case "response":
        return `${name} Responded to Sent Notification`;
      case "other_med":
        return `${name} is having some trouble with their medications please take a look at their medications and contact them accordingly.`;
      case "other_appt":
        return `${name} is having trouble with their appointments please take a look at their appointments and contact them accordingly.`;
      default:
        break;
    }
  };

  const statusSwitch = (param) => {
    switch (param) {
      case "completed":
        return "Completed";
      case "pending":
        return "In Progress";
      case "notStarted":
        return "Not Started";
      default:
        break;
    }
  };

  const queryClient = useQueryClient();

  const { caremanagerAlerts, isLoading, isFetching } = useQuery(
    ["userCareAlerts"],
    () => api.getPatientCareAlerts({ id: patient.id }),
    {
      onSuccess: (data) => {
        setCareAlerts(data);
        console.log(data);
      },
    }
  );

  const { mutate: sendAlert, isSuccess } = useMutation(api.sendCareAlert, {
    onSuccess: () => {
      toast.success("Sent Voice Message to Patient");
      queryClient.invalidateQueries("userCareAlerts");
      setActiveTab(3);
    },
    onError: () => {
      toast.error("Voice message failed to send");
    },
  });

  const audioStart = () => {
    Mp3Recorder.start()
      .then(() => {
        setIsRecording(true);
      })
      .catch((e) => console.error(e));
  };

  const audioStop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        setBlobURL(blobURL);
        setAudioData(blob);
        setIsRecording(false);
      })
      .catch((e) => console.log(e));
  };

  const sendCareAlert = (obj) => {
    var today = new Date();
    let dateTime =
      today.getFullYear() +
      (today.getMonth() + 1) +
      today.getDate() +
      "T" +
      today.getHours() +
      today.getMinutes() +
      today.getSeconds();

    const random = Math.floor(Math.random() * 9000 + 1000);

    let s3ID = patient.id + "_" + dateTime + "_" + random + ".mp3";

    let toSend = {};

    toSend.patient = patient.id;
    toSend.s3id = s3ID;
    toSend.key = random;
    toSend.status = "notStarted";
    toSend.message = obj.question;

    if (obj.res_required == "required") toSend.response_required = true;
    else toSend.response_required = false;

    sendAlert(toSend);

    axios
      .put(
        "https://8eibmk3co7.execute-api.us-east-2.amazonaws.com/v1/3srubixv2184809-dev/public/" +
          s3ID,
        audioData
      )
      .then(function (response) {
        toast.success("Uploaded Voice Recording to Server");
        console.log(response);
      })
      .catch(function (err) {
        toast.error("Voice Message Error Thrown");
        console.log(err);
      });
  };

  const showCompletedOnRowClick = (params) => {
    setOpen(true);
    setModalData(params);

    console.log(params);
  };

  return (
    <React.Fragment>
      <div
        className="ms-3 p-3"
        style={{
          width: "100%",
          height: 727,
          border: "1px solid lightgrey",
          borderRadius: "5px",
        }}
      >
        <div className="d-flex">
          <h4 className="fw-light mb-3">Notifications</h4>
          <button
            className="btn ms-auto btn-lg mb-2 text-light"
            style={{ backgroundColor: "#107869", width: 256 }}
            onClick={() => {
              setActiveTab(4);
            }}
          >
            Send a Message
          </button>
        </div>

        <div>
          <Nav tabs>
            <NavItem style={{ cursor: "pointer" }}>
              <NavLink
                className={activeTab === 1 ? "active" : ""}
                style={{ color: "#107869" }}
                onClick={() => {
                  setActiveTab(1);
                }}
              >
                Pending
              </NavLink>
            </NavItem>
            <NavItem style={{ cursor: "pointer" }}>
              <NavLink
                className={activeTab === 2 ? "active" : ""}
                style={{ color: "#107869" }}
                onClick={() => {
                  setActiveTab(2);
                }}
              >
                Completed
              </NavLink>
            </NavItem>
            <NavItem style={{ cursor: "pointer" }}>
              <NavLink
                className={activeTab === 3 ? "active" : ""}
                style={{ color: "#107869" }}
                onClick={() => {
                  setActiveTab(3);
                }}
              >
                Sent Messages
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
              <div
                className="mt-3"
                style={{
                  width: "100%",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                }}
              >
                {data.length !== 0 ? (
                  <ReactTable
                    columns={NOTIFSCOLUMNS}
                    data={data.filter((ea) => ea.status == "notStarted")}
                    isLoading={false}
                    getOnClick={onRowClick}
                  />
                ) : (
                  <p>Patient has not sent any alerts at this time...</p>
                )}
              </div>
            </TabPane>
            <TabPane tabId={2}>
              <div
                className="mt-3"
                style={{
                  width: "100%",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                }}
              >
                {data.length !== 0 ? (
                  <ReactTable
                    columns={NOTIFSCOLUMNS}
                    data={data.filter((ea) => ea.status == "completed")}
                    isLoading={false}
                    getOnClick={showCompletedOnRowClick}
                  />
                ) : (
                  <p>There are no completed alerts...</p>
                )}
              </div>
            </TabPane>
            <TabPane tabId={3}>
              <div className="mt-3">
                {data.length !== 0 ? (
                  <ReactTable
                    columns={COLUMNS}
                    data={careAlerts}
                    isLoading={false}
                    getOnClick={onRowClick}
                  />
                ) : (
                  <p>There are no sent messages...</p>
                )}
              </div>
            </TabPane>
            <TabPane tabId={4}>
              <div
                className="mt-3 p-3"
                style={{ border: "1px solid lightgrey", borderRadius: "5px" }}
              >
                {isRecording ? <i class="fa fa-circle text-danger"></i> : <></>}
                <audio src={blobURL} controls="controls" />
                <div className="mt-3">
                  <button
                    className="btn text-light me-3"
                    style={{ backgroundColor: "#107869", width: 128 }}
                    onClick={audioStart}
                  >
                    Start
                  </button>
                  <button
                    className="btn text-light"
                    style={{ backgroundColor: "#f7ab48", width: 128 }}
                    onClick={audioStop}
                    disabled={!isRecording}
                  >
                    Stop
                  </button>

                  <div className="mt-5">
                    <Form
                      onSubmit={(data) => {
                        sendCareAlert(data);
                        //console.log(data);
                      }}
                      onChange={(data) => {
                        console.log(data);
                      }}
                    >
                      {({ handleSubmit }) => (
                        <FormReact onSubmit={handleSubmit} className="">
                          <h4 className="fw-light">What is this for?</h4>
                          <Field name="question">
                            {({ input }) => (
                              <select
                                className="text-center p-2"
                                name={input.name}
                                style={{ width: "100%" }}
                                onChange={(value) => input.onChange(value)}
                              >
                                <option key="none" value="none">
                                  Select Option
                                </option>
                                {options.map((ea) => (
                                  <option key={ea} value={ea}>
                                    {ea}
                                  </option>
                                ))}
                              </select>
                            )}
                          </Field>

                          <h4 className="mt-3 fw-light">
                            Is patient response required?
                          </h4>
                          <Field name="res_required">
                            {({ input }) => (
                              <select
                                className="text-center p-2"
                                name={input.name}
                                style={{ width: "100%" }}
                                onChange={(value) => input.onChange(value)}
                              >
                                <option key="none" value="required">
                                  Select an Option
                                </option>

                                <option key="required" value="required">
                                  Patient Response Required
                                </option>

                                <option key="not_required" value="notRequired">
                                  No Response Required
                                </option>
                              </select>
                            )}
                          </Field>

                          <button
                            className="btn text-light mt-3 btn-md"
                            style={{ backgroundColor: "#107869", width: 256 }}
                            type="submit"
                          >
                            Send Message
                          </button>
                        </FormReact>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>

      <Modal isOpen={isOpen} size="lg" toggle={() => setOpen(!isOpen)}>
        <div>
          <Card className="p-5 ">
            <CardHeader>
              <div className="d-flex">
                <h1 className="fw-light">
                  {modalData.patient.first_name} {modalData.patient.last_name}
                </h1>
                <button
                  className="btn btn-danger ms-auto"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
              </div>
            </CardHeader>
            <CardBody>
              <h4 className="fw-light mb-2">
                <span className="h5 text-muted">Completed At:</span>{" "}
                {modalData.completed_at}
              </h4>
              <h4 className="fw-light mb-2">
                <span className="h5 text-muted">Care Manager Comment:</span>{" "}
                {modalData.comment}
              </h4>
              <h4 className="fw-light mb-2">
                <span className="h5 text-muted">Patient Response:</span>{" "}
                {modalData.response}
              </h4>
              <h4 className="fw-light mb-2">
                <span className="h5 text-muted">Alert Description:</span>{" "}
                {descriptorSwitch(modalData.type, modalData.patient.first_name)}
              </h4>
              <h4 className="fw-light mb-2">
                <span className="h5 text-muted">Alert Type:</span>{" "}
                {typeSwitch(modalData.type)}
              </h4>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default PatientNotifications;
