import React, { useState } from "react";

// Third Party Libraries
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

// APIs
import * as api from "../../services";
import LoadingSpinner from "./UI/loadingSpinner";

const Notification = (props) => {
  const [modal, setModal] = useState(false);
  const [comment, setComment] = useState("");
  const [outcome, setOutcome] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const data = location.state.data;

  const queryClient = useQueryClient();
  const { mutate: completeAlert, isSuccess } = useMutation(api.completeAlert, {
    onSuccess: (data) => {
      toast.success("Patient Alert Completed");
      navigate("/notifications");
    },
  });

  const headerSwitch = (param) => {
    switch (param) {
      case "med_help":
        return "I Need Help With My Medication";
      case "appt_help":
        return "I Need Help With My Appointment";
      case "feelsick":
        return "Feel Sick Form";
      case "homesurvey":
        return "Just got Home";
      case "response":
        return "Response to Sent Notification";
      default:
        break;
    }
  };

  const descriptorSwitch = (param, name) => {
    switch (param) {
      case "med_help":
        return `${name} is having some trouble with their medications please take a look at their medications and contact them accordingly.`;
      case "appt_help":
        return `${name} is having trouble with their appointments please take a look at their appointments and contact them accordingly.`;
      case "feelsick":
        return `${name} is feeling sick please take a look at their response and contact them accordingly.`;
      case "homesurvey":
        return `${name} Just got Home`;
      case "response":
        return `${name} Responded to Sent Notification`;
      case "other_med":
        return `${name} is having some trouble with their medications please take a look at their medications and contact them accordingly.`;
      case "other_appt":
        return `${name} is having trouble with their appointments please take a look at their appointments and contact them accordingly.`;
      default:
        break;
    }
  };

  const typeSwitch = (param) => {
    switch (param) {
      case "med_help":
        return "Medication Help";
      case "appt_help":
        return "Appointment Help";
      case "feelsick":
        return "I Feel Sick";
      case "homesurvey":
        return "Home Survey";
      case "response":
        return "Response";
      case "other_med":
        return "Other";
      case "other_appt":
        return "Other";
      default:
        break;
    }
  };

  const statusSwitch = (param) => {
    switch (param) {
      case "completed":
        return "Completed";
      case "pending":
        return "In Progress";
      case "notStarted":
        return "Not Started";
      default:
        break;
    }
  };

  const onComplete = () => {
    let toSend = {
      id: data.id,
      comment: outcome,
    };

    completeAlert(toSend);

    queryClient.invalidateQueries("alerts");
  };

  const toggle = () => setModal(!modal);

  return (
    <React.Fragment>
      <div className="" style={{ margin: "5%" }}>
        <React.Fragment>
          <h1>{headerSwitch(data.type)}</h1>
          <hr></hr>
        </React.Fragment>

        <div
          className=""
          style={{ marginTop: "100px", marginBottom: "100px", width: "100%" }}
        >
          <h3 className="fw-light text-center">
            {descriptorSwitch(
              data.type,
              `${data.patient.first_name} ${data.patient.last_name}`
            )}
          </h3>
        </div>

        <table
          className="table table-borderless"
          style={{
            width: "100%",
          }}
        >
          <thead
            style={{
              borderBottom: "1px solid lightGrey",
              borderTop: "1px solid lightGrey",
            }}
          >
            <tr>
              <th className="fw-bold" style={{ width: "20%" }}>
                Type
              </th>
              <th className="fw-bold" style={{ width: "20%" }}>
                Patient Full Name
              </th>
              <th className="fw-bold" style={{ width: "20%" }}>
                Patient Response
              </th>
              <th className="fw-bold" style={{ width: "20%" }}>
                Phone
              </th>
              <th className="fw-bold" style={{ width: "20%" }}>
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{typeSwitch(data.type)}</td>
              <td>
                {data.patient.first_name} {data.patient.last_name}
              </td>
              <td>{data.response}</td>
              <td>{data.patient.phone}</td>
              <td className="text-danger">{statusSwitch(data.status)}</td>
            </tr>
          </tbody>
        </table>

        {/* <div className="d-flex justify-content-center mt-5">
          <h3 className="fw-light mt-1">Enter Comments Here: </h3>
          <textarea
            className="ms-3"
            style={{ width: "50%" }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
        </div> */}

        <div style={{ marginTop: "150px" }}></div>

        <div className="mt-5 d-grid gap-2 col-6 mx-auto">
          <button
            className="btn p-3 text-light"
            style={{ backgroundColor: "#107869" }}
            onClick={toggle}
          >
            <h3 className="font-weight-bold text-center">
              Complete Notification
            </h3>
          </button>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Completed Notification</ModalHeader>
        <ModalBody>
          Your are about to complete this notification, this action cannot be
          undone. You are certifying that you have contacted the patient and
          resolved their question.
          <h5 className="fw-light mt-3">
            Please Enter Outcome from Encounter Below
          </h5>
          <textarea
            className="mt-1"
            style={{ width: "100%" }}
            placeholder=" Outcome"
            value={outcome}
            onChange={(e) => setOutcome(e.target.value)}
          ></textarea>
          {isSuccess ? (
            <LoadingSpinner />
          ) : (
            <button
              className="btn text-light"
              style={{ backgroundColor: "#107869" }}
              onClick={() => onComplete()}
            >
              Complete
            </button>
          )}
        </ModalBody>
        <ModalFooter>
          <button
            className="btn text-light"
            style={{ backgroundColor: "#f7ab48" }}
            onClick={toggle}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Notification;
