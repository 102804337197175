// Root
import React, { Component } from 'react';

// External Libraries
import { Form, Field } from 'react-final-form';
import { Form as FormReact, Container } from 'reactstrap';
import axios from 'axios';
import Auth from '@aws-amplify/auth';

// Components
import { HeaderMain } from '../common/UI/headerMain';
import OnSuccess from './onSuccess';

class CareManagerOnBoarding extends Component {
    state = { 
        isLoading: false,
        onSuccess: false,
        user: {},
        userData: {}
     }

    async signUp(dataObj) {

        const { REACT_APP_ONBOARD_CARE } = process.env;

        let username = dataObj.email;
        let password = dataObj.password;
        let email = dataObj.email;

        try {
            const { user } = await Auth.signUp({
                username,
                password,
                attributes: {
                    email
                }
            });
            console.log(user);
            axios.post(REACT_APP_ONBOARD_CARE, dataObj);
            this.setState({ isLoading: false, onSuccess: true, user: user, userData: dataObj })
        } catch (error) {
            console.log('error signing up:', error);
        }

    }

    render() { 

        const { isLoading, onSuccess, user, userData } = this.state;

        return (
            <>
                {onSuccess == true ? (
                    <OnSuccess 
                        user = {user}
                        userData = {userData}
                    />
                    ) : (
                    <React.Fragment>

                    {/* Header & Sub Header*/}
                    <Container className="mt-5 mb-5">
                        <HeaderMain title="Care Manager OnBoarding" subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."/>

                        <div className="">
                            <Form
                                onSubmit={(dataObj) => {
                                    this.setState({ isLoading: true });
                                    this.signUp(dataObj);
                                }}
                            >
                                {({ handleSubmit }) => (
                                    <FormReact
                                        onSubmit={ handleSubmit }
                                        className=""
                                        style={{ width: "75%", height: "100%" }}
                                    >
                                        <Field name="firstName">
                                            {({ input }) => (
                                                <input 
                                                    className="mt-3 p-2"
                                                    style={{ width: "40%"}}
                                                    placeholder="First Name"
                                                    type="text"
                                                    {...input}
                                                />
                                            )}
                                        </Field>

                                        <Field name="mi">
                                            {({ input }) => (
                                                <input 
                                                    className="mt-3 ms-2 p-2"
                                                    style={{ width: "10%"}}
                                                    placeholder="MI"
                                                    type="text"
                                                    {...input}
                                                />
                                            )}
                                        </Field>

                                        <Field name="lastName">
                                            {({ input }) => (
                                                <input 
                                                    className="mt-3 ms-2 p-2"
                                                    style={{ width: "40%"}}
                                                    placeholder="Last Name"
                                                    type="text"
                                                    {...input}
                                                />
                                            )}
                                        </Field>

                                        <Field name="email">
                                            {({ input }) => (
                                                <input 
                                                    className="mt-3 p-2"
                                                    style={{ width: "60%"}}
                                                    placeholder="Email Address"
                                                    type="text"
                                                    {...input}
                                                />
                                            )}
                                        </Field>

                                        <Field name="phone">
                                            {({ input }) => (
                                                <input 
                                                    className="mt-3 ms-3 p-2"
                                                    style={{ width: "30%"}}
                                                    placeholder="Phone #"
                                                    type="text"
                                                    {...input}
                                                />
                                            )}
                                        </Field>

                                        <Field name="username">
                                            {({ input }) => (
                                                <input 
                                                    className="mt-3 p-2"
                                                    style={{ width: "45%"}}
                                                    placeholder="Account User Name"
                                                    type="text"
                                                    {...input}
                                                />
                                            )}
                                        </Field>

                                        <Field name="password">
                                            {({ input }) => (
                                                <input 
                                                    className="mt-3 ms-3 p-2"
                                                    style={{ width: "45%"}}
                                                    placeholder="Account Password"
                                                    type="text"
                                                    {...input}
                                                />
                                            )}
                                        </Field>

                                        <Field name="passwordValidate">
                                            {({ input }) => (
                                                <input 
                                                    className="mt-3 p-2"
                                                    style={{ width: "45%"}}
                                                    placeholder="Re-Enter Account Password"
                                                    type="text"
                                                    {...input}
                                                />
                                            )}
                                        </Field>

                                        <div>
                                            {isLoading == true ? 
                                                (
                                                    <div class="mt-5 spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <button className="btn btn-md btn-primary mt-5" type="submit">Add Care Manager</button>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        

                                    </FormReact>
                                )}
                                
                            </Form>
                        </div>

                    </Container>
                    </React.Fragment>
                )}
            </>
        );
    }
}
 
export default CareManagerOnBoarding;