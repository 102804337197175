import React, { useState } from "react";

// Third Party Libraries
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Modal, Form as FormReact, Card } from "reactstrap";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import _, { each } from "lodash";
import { toast } from "react-toastify";

// Components
import ReactTable from "../UI/reactTable";
import LoadingSpinner from "../UI/loadingSpinner";

// Services
import * as api from "../../../service/medService";

// Columns
import { COLUMNS } from "../../../columns/medicationColumns";

const Medications = ({ id, providers }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);

  const { data, isLoading, isFetching } = useQuery(
    ["medications"],
    () => api.getMedications({ id }),
    {
      onSuccess: (data) => {
        //console.log(data);
      },
    }
  );

  const queryClient = useQueryClient();

  const { mutate: addMedications } = useMutation(api.addMedications, {
    onSuccess: () => {
      queryClient.invalidateQueries("medications");
      toast.success("Medication Added Successfully");
    },
    onError: () => {
      toast.error("Medication Failed to Add");
    },
  });

  const { mutate: deleteMedications } = useMutation(api.deleteMedications, {
    onSuccess: () => {
      queryClient.invalidateQueries("medications");
      toast.success("Medication was deleted");
    },
    onError: () => {
      toast.error("Medication was not deleted");
    },
  });

  const onRowClick = () => {};

  const onAddHandler = (data) => {
    data.patient = id;

    addMedications(data);
    setShowAddModal(false);
  };

  const onDeleteHandler = (id) => {
    let obj = {};
    obj.med_id = id;
    console.log(obj);

    deleteMedications(obj);
  };

  return (
    <div>
      {isLoading === true ? (
        <LoadingSpinner />
      ) : (
        <React.Fragment>
          <div className="d-flex mb-3">
            <h4 className="fw-bold">Current Medications</h4>
            <button
              className="btn btn-lg text-light ms-auto"
              style={{ backgroundColor: "#107869", width: 256 }}
              onClick={() => setShowAddModal(true)}
            >
              Add Medication
            </button>
          </div>
          <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            {/* <ReactTable
                        onDeleteHandler={onDeleteHandler}
                        columns={COLUMNS}
                        data={data}
                        getOnClick={onRowClick}
                        isLoading={false}
                    /> */}

            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="fw-bold">Medication</th>
                  <th className="fw-bold">Strength</th>
                  <th className="fw-bold">Drug Class</th>
                  <th className="fw-bold">Instructions</th>
                  <th className="fw-bold">Provider</th>
                  <th className="fw-bold">Date Added</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map((ea) => (
                  <tr>
                    <td>{ea.name}</td>
                    <td>{ea.strength}</td>
                    <td>{ea.drugclass}</td>
                    <td>{ea.instructions}</td>
                    <td>{ea.provider}</td>
                    <td>{ea.created_at}</td>
                    <td>
                      <i
                        className="fa fa-trash"
                        onClick={() => onDeleteHandler(ea.id)}
                        style={{ cursor: "pointer", color: "#f7ab48" }}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p className="text-muted fw-light fst-italic">
              {isFetching
                ? "Refreshing..."
                : "Refreshed at " + new Date().toLocaleTimeString()}
            </p>
          </div>

          <Modal
            isOpen={showAddModal}
            size="lg"
            centered
            toggle={() => setShowAddModal(!showAddModal)}
          >
            <div className="m-5" style={{}}>
              <div className="d-flex">
                <h1>Add Medication</h1>
                <button
                  className="btn btn-md ms-auto"
                  type="button"
                  onClick={() => setShowAddModal(false)}
                >
                  <i className="fa fa-close fa-2x" style={{ color: "#f7ab48" }}></i>
                </button>
              </div>
              <hr></hr>
              <div className="d-flex">
                <Card className="p-3 me-2 bg-light" style={{ width: "30%" }}>
                  <p className="fw-bold">Medication Settings</p>
                  <p className="text-muted">Add Medication to the left</p>
                </Card>
                <div className="ps-4" style={{ width: "70%" }}>
                  <Form
                    onSubmit={(dataObj) => {
                      console.log(dataObj);
                      if (_.isEmpty(dataObj)) {
                        setShowAddModal(false);
                      } else {
                        onAddHandler(dataObj);
                      }
                    }}
                    mutators={{
                      // potentially other mutators could be merged here
                      ...arrayMutators,
                    }}
                  >
                    {({ handleSubmit }) => (
                      <FormReact
                        onSubmit={handleSubmit}
                        className="mb-3"
                        // style={{ width: "75%", height: "100%" }}
                      >
                        <Field name="provider">
                          {({ input }) => (
                            <select
                              className="form-control mt-2 p-2"
                              name={input.name}
                              style={{
                                width: "90%",
                                border: "1px lightgrey solid",
                                borderRadius: "10px",
                              }}
                              onChange={(value) => input.onChange(value)}
                            >
                              <option key="none" value="none">
                                Choose a Provider
                              </option>
                              {providers.map((ea) => (
                                <option
                                  key={ea.id}
                                  value={`${ea.first_name} ${ea.last_name} MD`}
                                >
                                  {ea.first_name} {ea.last_name}
                                </option>
                              ))}
                            </select>
                          )}
                        </Field>
                        <h5 className="mt-4 fw-light">Name of Medication</h5>
                        <Field name="name">
                          {({ input }) => (
                            <input
                              className="p-2"
                              style={{
                                width: "90%",
                                border: "1px lightgrey solid",
                                borderRadius: "10px",
                              }}
                              placeholder=""
                              type="text"
                              required
                              {...input}
                            />
                          )}
                        </Field>

                        <div className="d-flex mt-4">
                          <div className="me-3">
                            <h5 className="fw-light">Strength</h5>
                            <Field name="strength">
                              {({ input }) => (
                                <input
                                  className="p-2"
                                  style={{
                                    border: "1px lightgrey solid",
                                    borderRadius: "10px",
                                  }}
                                  placeholder="20 mg"
                                  type="text"
                                  required
                                  {...input}
                                />
                              )}
                            </Field>
                          </div>

                          <div>
                            <h5 className="fw-light">Drug Class</h5>
                            <Field name="drugclass">
                              {({ input }) => (
                                <input
                                  className="p-2"
                                  style={{
                                    border: "1px lightgrey solid",
                                    borderRadius: "10px",
                                  }}
                                  placeholder="NSID"
                                  type="text"
                                  required
                                  {...input}
                                />
                              )}
                            </Field>
                          </div>
                        </div>

                        <h5 className="mt-4 fw-light">Instructions</h5>
                        <Field name="instructions">
                          {({ input }) => (
                            <input
                              className="p-2"
                              style={{
                                width: "90%",
                                border: "1px lightgrey solid",
                                borderRadius: "10px",
                              }}
                              placeholder="Take 2 Tablets..."
                              type="text"
                              required
                              {...input}
                            />
                          )}
                        </Field>

                        <button
                          className="btn btn-lg text-light mt-3"
                          style={{ backgroundColor: "#107869", width: 256 }}
                          type="submit"
                        >
                          Add Medication
                        </button>
                      </FormReact>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      )}
    </div>
  );
};

export default Medications;
