// Root
import React from "react";

// External Libraries
import { Form, Field } from "react-final-form";
import { Form as FormReact } from "reactstrap";
import { Card, CardBody } from "reactstrap";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

// Temp
import MicRecorder from "mic-recorder-to-mp3";

// CSS
import "audio-react-recorder/dist/index.css";
import { useState } from "react";

// Services
import * as api from "../../services";

const Mp3Recorder = new MicRecorder({ bitRate: 64 });

const CreateNotification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const patient = location.state.data;

  const [modal, setModal] = useState(false);
  const [recordState, setRecordState] = useState(null);
  const [audioData, setAudioData] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState("");
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState([
    "Medication Reminder",
    "Appointment Reminder",
    "Changes to Care Plan",
    "Care Manager Touchbase",
    "Responding to Patient Request",
  ]);

  const toggle = () => {
    setModal(!modal);
  };

  const start = () => {
    setRecordState(RecordState.START);
  };

  const pause = () => {
    setRecordState(RecordState.PAUSE);
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
  };

  //audioData contains blob and blobUrl
  const onStop = (data) => {
    setAudioData(data);
    console.log("onStop: audio data", data);
  };

  const { mutate: sendAlert, isSuccess } = useMutation(api.sendCareAlert);

  const sendNotif = () => {
    //const { REACT_APP_UPLOAD_AUDIO } = process.env;
    //const { audioData, patient, msg } = this.state;

    setModal(true);

    var today = new Date();
    let dateTime =
      today.getFullYear() +
      (today.getMonth() + 1) +
      today.getDate() +
      "T" +
      today.getHours() +
      today.getMinutes() +
      today.getSeconds();

    const random = Math.floor(Math.random() * 9000 + 1000);

    let s3ID = patient.id + "_" + dateTime + "_" + random + ".mp3";

    let toSend = {};

    toSend.patient_id = patient.id;
    toSend.s3id = s3ID;
    toSend.key = random;
    toSend.status = "notStarted";
    toSend.message = message.question;

    sendAlert(toSend);

    axios
      .put(
        "https://8eibmk3co7.execute-api.us-east-2.amazonaws.com/v1/3srubixv2184809-dev/public/" +
          s3ID,
        audioData
      )
      .then(function (response) {
        console.log("Uploaded to s3 response: ");
        console.log(response);
      })
      .catch(function (err) {
        console.log("error thrown:");
        console.log(err);
      });
  };

  const audioStart = () => {
    Mp3Recorder.start()
      .then(() => {
        setIsRecording(true);
      })
      .catch((e) => console.error(e));
  };

  const audioStop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        setBlobURL(blobURL);
        setAudioData(blob);
        setIsRecording(false);
      })
      .catch((e) => console.log(e));
  };

  return (
    <React.Fragment>
      <div
        className=""
        style={{
          marginLeft: "15%",
          marginRight: "15%",
          marginTop: "5%",
          marginBottom: "5%",
        }}
      >
        <React.Fragment>
          <h1>
            Create Notification for {patient.first_name} {patient.mi}{" "}
            {patient.last_name}
          </h1>
          <hr></hr>
        </React.Fragment>

        <div
          className=""
          style={{ marginTop: "100px", marginBottom: "100px", width: "100%" }}
        >
          <h3 className="fw-light text-center">
            Please record your message you want to send to patient on the right
            box and choose the response the patient will see on their screen
            once they receive the notification.
          </h3>
        </div>

        <div className="d-flex mt-5 mb-5">
          <div
            style={{
              width: "90%",
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <h3 className="fw-light">Patient Details</h3>

              {/* Patient Details Table */}
              <Card className="mb-3" style={{ width: "75%" }}>
                <CardBody>
                  <React.Fragment>
                    <div className="d-flex justify-content-center my-3">
                      <h3>
                        {patient.first_name} {patient.last_name}
                      </h3>
                    </div>
                    <div className="mb-4 text-center">
                      <a className="h6 text-decoration-none" href="#">
                        Date of SNF: {patient.dc_date}
                      </a>
                      <div className="text-center">
                        <i className="fa fa-map-marker me-2"></i>
                        Admission Facility: {patient.facility.name}
                      </div>
                      <div className="mt-4 text-center pb-1">
                        <ul className="list-inline">
                          <li className="list-inline-item text-center">
                            <h4 className="mb-1">
                              {patient.managed_by.first_name}{" "}
                              {patient.managed_by.last_name}
                            </h4>
                            <span>Care Coordinator</span>
                          </li>
                          <li className="list-inline-item text-center">
                            <h4 className="mb-1">
                              {patient.providers[0].first_name}{" "}
                              {patient.providers[0].last_name}
                            </h4>
                            <span>Provider</span>
                          </li>
                        </ul>
                      </div>
                      <div className="text-center">
                        <i className="mr-1"></i>
                        Phone: {patient.phone}
                      </div>
                      <div className="text-center">
                        <i className="mr-1"></i>
                        Address: {patient.address}, {patient.city} {patient.state}, {patient.zip}
                      </div>
                      <div className="text-center">
                        <i className="mr-1"></i>
                        DOB: {patient.dob}
                      </div>
                    </div>
                  </React.Fragment>
                  <div className="text-center mb-4">
                    <div className="mb-2">
                      <h5 className="fw-light">Hospital Discharge Diagnosis</h5>
                      {patient.dc_diagnosis}
                    </div>
                  </div>

                  <div className="text-center mb-4">
                    <div className="mb-2">
                      <h5 className="fw-light">SNF Admit Diagnosis</h5>
                      {patient.admit_diagnosis}
                    </div>
                  </div>

                  <div className="text-center mb-4">
                    <div className="mb-2">
                      <h5 className="fw-light">Code Status</h5>
                      <p>{patient.status_code}</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
          {/* Notificaition Form */}

          <div className="text-center p-3" style={{ width: "50%" }}>
            {/* Heading */}
            <h3 className="fw-light text-center mb-3">Notification Form</h3>
            {isRecording ?  <i class="fa fa-circle text-danger"></i> : <></>}
            <audio src={blobURL} controls="controls" />
            <div className="mt-3">
              <button className="btn btn-primary me-3" onClick={audioStart}>
                Start
              </button>
              <button
                className="btn btn-danger"
                onClick={audioStop}
                disabled={!isRecording}
              >
                Stop
              </button>
            </div>

            {/* React Audio Recorder
              <AudioReactRecorder
                state={recordState}
                type='audio/wav'
                onStop={this.onStop}
                foregroundColor="blue"
                canvssWidth="100"
                canvasHeight="60"
              />

              <audio
                id="audio"
                controls
                src={this.state.audioData ? this.state.audioData.url : null}
              ></audio>

              <div>
                <button onClick={this.start} className="btn btn-primary m-2">
                  Start
                </button>
                <button onClick={this.pause} className="btn btn-warning m-2">
                  Pause
                </button>
                <button onClick={this.stop} className="btn btn-danger m-2">
                  Stop
                </button>
              </div> */}

            {/* Text Form */}
            <div className="mt-5">
              <h3 className="fw-light">What is this for?</h3>

              <Form
                onSubmit={(data) => {
                  setMessage(data);
                }}
                onChange={(data) => {
                  console.log(data);
                }}
              >
                {({ handleSubmit }) => (
                  <FormReact onSubmit={handleSubmit} className="">
                    <Field name="question">
                      {({ input }) => (
                        <select
                          className="text-center mt-3 p-2"
                          name={input.name}
                          style={{ width: "45%" }}
                          onChange={(value) => input.onChange(value)}
                        >
                          <option key="none" value="none">
                            Select Option
                          </option>
                          {options.map((ea) => (
                            <option key={ea} value={ea}>
                              {ea}
                            </option>
                          ))}
                        </select>
                      )}
                    </Field>

                    <button
                      className="btn btn-primary mb-2 ms-2 btn-md"
                      type="submit"
                    >
                      Complete
                    </button>
                  </FormReact>
                )}
              </Form>
            </div>
          </div>
        </div>

        <div>
          {/* Medication Table */}
          <div
            style={{
              width: "100%",
            }}
          >
            <h3 className="fw-light">Medications</h3>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{ width: "25%" }} className="fw-light">
                    Name
                  </th>
                  <th style={{ width: "15%" }} className="fw-light">
                    Strength
                  </th>
                  <th style={{ width: "20%" }} className="fw-light">
                    Directions
                  </th>
                  <th style={{ width: "20%" }} className="fw-light">
                    Class
                  </th>
                  <th style={{ width: "25%" }} className="fw-light">
                    Prescribed By
                  </th>
                </tr>
              </thead>
              <tbody>
                {patient.meds.map((med) => (
                  <tr style={{ cursor: "pointer" }} key={med.name}>
                    <td>{med.name}</td>
                    <td>{med.strength}</td>
                    <td>{med.instructions}</td>
                    <td>{med.drugclass}</td>
                    <td>
                      {med.provider}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Appointments Table */}
          <div
            className=""
            style={{
              width: "100%",
            }}
          >
            <h3 className="fw-light">Upcoming Appointments</h3>

            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{ width: "10%" }} className="fw-light">
                    Date
                  </th>
                  <th style={{ width: "10%" }} className="fw-light">
                    Time
                  </th>
                  <th style={{ width: "20%" }} className="fw-light">
                    Reason
                  </th>
                  <th style={{ width: "20%" }} className="fw-light">
                    Facility
                  </th>
                  <th style={{ width: "20%" }} className="fw-light">
                    Address
                  </th>
                  <th style={{ width: "25%" }} className="fw-light">
                    Provider
                  </th>
                </tr>
              </thead>
              <tbody>
                {patient.visits.map((visit) => (
                  <tr style={{ cursor: "pointer" }} key={visit.name}>
                    <td>{visit.date}</td>
                    <td>{visit.time}</td>
                    <td>{visit.reason}</td>
                    <td>{visit.facility}</td>
                    <td>{visit.address}, {visit.city} {visit.state}, {visit.zip}</td>
                    <td>
                      {visit.provider}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Providers Table */}
          {/* <div
            className=""
            style={{
              width: "100%",
            }}
          >
            <h3 className="fw-light">CCM Providers</h3>

            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="fw-light">Full Name</th>
                  <th className="fw-light">Type</th>
                  <th className="fw-light">Phone</th>
                  <th className="fw-light">Active</th>
                </tr>
              </thead>
              <tbody>
                {providers.length !== 0 ? (
                  providers.map((ea) => (
                    <tr style={{ cursor: "pointer" }} key={ea.name}>
                      <td>{ea.name}</td>
                      <td>{ea.type}</td>
                      <td>{ea.phone}</td>
                      <td>Yes</td>
                    </tr>
                  ))
                ) : (
                  <p className="fw-light ms-2">None</p>
                )}
              </tbody>
            </table>
          </div> */}

          {/* Contacts Table */}
          {/* <div
            className=""
            style={{
              width: "100%",
            }}
          >
            <h3 className="fw-light">Emergency Contacts</h3>

            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="fw-light">Relationship</th>
                  <th className="fw-light">Full Name</th>
                  <th className="fw-light">Phone</th>
                </tr>
              </thead>
              <tbody>
                {contacts.length !== 0 ? (
                  contacts.map((ea) => (
                    <tr style={{ cursor: "pointer" }} key={ea.name}>
                      <td>{ea.relation}</td>
                      <td>{ea.name}</td>
                      <td>{ea.phone}</td>
                    </tr>
                  ))
                ) : (
                  <p className="fw-light ms-2">None</p>
                )}
              </tbody>
            </table>
          </div>
        </div> */}

          <div style={{ marginTop: "150px" }}></div>

          <div className="mt-5 d-grid gap-2 col-6 mx-auto">
            <button
              className="btn p-3 btn-primary"
              onClick={(e) => sendNotif()}
            >
              <h3 className="font-weight-bold text-center">
                Send Notification
              </h3>
            </button>
          </div>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Completed Notification</ModalHeader>
        <ModalBody>
          <h5 className="fw-light">
            Your notification has been sent to patient, a response notification
            will appear in Pending Notifications once the patient has responded.
          </h5>
          <button
            className="btn btn-primary mt-5"
            onClick={() => navigate("/home")}
          >
            Go Home
          </button>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-danger" onClick={toggle}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default CreateNotification;
