export const COLUMNS = [
  {
    Header: "Status",
    accessor: "status",
    Cell: (row) => {
      switch (row.value) {
        case "notStarted":
          return <span className="text-danger">Not Started</span>;
        case "completed":
          return <span className="text-success">Completed</span>;
        default:
          return "N/A";
      }
    },
  },
  {
    Header: "Message",
    accessor: "message",
  },
  {
    Header: "Response Required",
    accessor: (d) => {
      if (d.response_required === true) return "Yes";
      else return "No";
    },
  },
  {
    Header: "Sent At",
    accessor: "created_at",
    Cell: (row) => {
      let event = new Date(row.value).toUTCString().substring(0, 16);
      return event.toLocaleString("en-US");
    },
  },
];
