import React from 'react';
import PropTypes from 'prop-types';

const HeaderMain = (props) => (
    <React.Fragment>
        { /* START H1 Header */}
        <div className={`${ props.className }`}>
            <h1 className="display-4 mr-3 mb-0 align-self-start">
                { props.title }
            </h1>

            <h5 className="text-left text-muted mt-5">
                { props.subTitle }
            </h5>
        </div>
        { /* END H1 Header */}
    </React.Fragment>
)

HeaderMain.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    className: PropTypes.string
};
HeaderMain.defaultProps = {
    title: "Waiting for Data...",
    subTitle: "Waiting for Data...",
    className: "my-4"
};

export { HeaderMain };