// Root
import React from "react";

// External Libraries
import { Container, Modal, Card, CardBody, CardHeader } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { Auth } from "aws-amplify";
import { useQuery } from "@tanstack/react-query";

// Components
import { HeaderMain } from "./common/UI/headerMain";
import ReactTable from "./common/UI/reactTable";

// Headers
import { COMPLETEDCOLUMNS } from "./reacttable/completedColumns";
import { NOTIFSCOLUMNS } from "./reacttable/notificationsColumns";
import { useState } from "react";

// Services
import * as api from "../services";
import LoadingSpinner from "./common/UI/loadingSpinner";

const Notifications = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [completed, setCompleted] = useState([]);

  const [modalData, setModalData] = useState({
    patient: {
      first_name: "placeholder",
      last_name: "placeholder",
    },
  });

  const { data, isLoading, isFetching } = useQuery(
    ["alerts"],
    () => api.getAlerts({ id: Auth.user.attributes["custom:id"] }),
    {
      onSuccess: (data) => {
        // for (let i = 0; i < data.length; i++) {
        //   if (data[i].status == "completed") completed.push(data[i]);
        //   else list.push(data[i]);
        // }
        let tmpList = [];
        let tmpCompleted = [];

        for (let i = 0; i < data.length; i++) {
          if (data[i].status == "completed") tmpCompleted.push(data[i]);
          else tmpList.push(data[i]);
        }

        setList(tmpList);
        setCompleted(tmpCompleted);
      },
    }
  );

  const onRowClick = (params) => {
    navigate(`/notification`, {
      state: { data: params },
    });
  };

  const onRowClickCompleted = (data) => {
    setOpen(true);
    setModalData(data);

    console.log(data);
  };

  const typeSwitch = (param) => {
    switch (param) {
      case "med_help":
        return "Medication Help";
      case "appt_help":
        return "Appointment Help";
      case "feelsick":
        return "I Feel Sick";
      case "homesurvey":
        return "Home Survey";
      case "response":
        return "Response";
      case "other_med":
        return "Other";
      case "other_appt":
        return "Other";
      default:
        break;
    }
  };

  const descriptorSwitch = (param, name) => {
    switch (param) {
      case "med_help":
        return `${name} is having some trouble with their medications please take a look at their medications and contact them accordingly.`;
      case "appt_help":
        return `${name} is having trouble with their appointments please take a look at their appointments and contact them accordingly.`;
      case "feelsick":
        return `${name} is feeling sick please take a look at their response and contact them accordingly.`;
      case "homesurvey":
        return `${name} Just got Home`;
      case "response":
        return `${name} Responded to Sent Notification`;
      case "other_med":
        return `${name} is having some trouble with their medications please take a look at their medications and contact them accordingly.`;
      case "other_appt":
        return `${name} is having trouble with their appointments please take a look at their appointments and contact them accordingly.`;
      default:
        break;
    }
  };

  const statusSwitch = (param) => {
    switch (param) {
      case "completed":
        return "Completed";
      case "pending":
        return "In Progress";
      case "notStarted":
        return "Not Started";
      default:
        break;
    }
  };

  const pendingCols = NOTIFSCOLUMNS;
  const completedCols = COMPLETEDCOLUMNS;

  return (
    <React.Fragment>
      {/* Header & Sub Header*/}
      <Container className="mt-5 mb-5">
        <HeaderMain
          title="Pending Notifications"
          subTitle="Here are the patients requiring your attention right away."
        />
      </Container>

      <Container>{isFetching ? <LoadingSpinner /> : <></>}</Container>

      <Container>
        <ReactTable
          columns={pendingCols}
          data={list}
          isLoading={false}
          getOnClick={onRowClick}
        />
      </Container>

      <div style={{ marginTop: "50px" }}></div>

      <Container className="mt-5 mb-5">
        <HeaderMain title="Completed Notifications" subTitle="" />
      </Container>

      <Container style={{ marginBottom: "25%" }}>
        <ReactTable
          columns={completedCols}
          data={completed}
          isLoading={false}
          getOnClick={onRowClickCompleted}
        />
      </Container>

      <Modal isOpen={isOpen} size="lg" toggle={() => setOpen(!isOpen)}>
        <div>
          <Card className="p-5 ">
            <CardHeader>
              <div className="d-flex">
                <h1 className="fw-light">
                  {modalData.patient.first_name} {modalData.patient.last_name}
                </h1>
                <button
                  className="btn text-light ms-auto"
                  style={{ backgroundColor: "#f7ab48" }}
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
              </div>
            </CardHeader>
            <CardBody>
              <h4 className="fw-light mb-2">
                <span className="h5 text-muted">Completed At:</span>{" "}
                {modalData.completed_at}
              </h4>
              <h4 className="fw-light mb-2">
                <span className="h5 text-muted">Care Manager Comment:</span>{" "}
                {modalData.comment}
              </h4>
              <h4 className="fw-light mb-2">
                <span className="h5 text-muted">Patient Response:</span>{" "}
                {modalData.response}
              </h4>
              <h4 className="fw-light mb-2">
                <span className="h5 text-muted">Alert Description:</span>{" "}
                {descriptorSwitch(modalData.type, modalData.patient.first_name)}
              </h4>
              <h4 className="fw-light mb-2">
                <span className="h5 text-muted">Alert Type:</span>{" "}
                {typeSwitch(modalData.type)}
              </h4>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Notifications;
