import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const getAllFacilities = () =>
  api.get("facility").then((res) => res.data);

// GET all medications, pass in patient ID
export const getFacilities = (params) =>
  api.post("facility", params).then((res) => res.data);

// Add medication(s), pass medication fields
export const addFacility = (params) =>
  api.put("facility", params).then((res) => res.data);

// Delete single medication, pass medication ID
export const deleteFacility = (params) =>
  api.delete("facility", params).then((res) => res.data);