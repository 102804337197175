import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

// GET all medications, pass in patient ID
export const getMedications = (params) =>
  api.post("medication", params).then((res) => res.data);

// Add medication(s), pass medication fields
export const addMedications = (params) =>
  api.put("medication", params).then((res) => res.data);

// Delete single medication, pass medication ID
export const deleteMedications = (params) =>
  api.delete("medication", { data: params }).then((res) => res.data);