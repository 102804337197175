import React from "react";
import { Container } from "reactstrap";
import { HeaderMain } from "../common/UI/headerMain";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Modal, Form as FormReact, Card } from "reactstrap";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import _, { each } from "lodash";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Services
import * as api from "../../service/providerService";
import ReactTable from "../common/UI/reactTable";

// Columns
import { COLUMNS } from "../reacttable/providerOnboardColumns";

const ProviderOnboarding = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: addProvider } = useMutation(api.addProvider, {
    onSuccess: () => {
      queryClient.invalidateQueries("onBoardProviderList");
      toast.success("Provider Added Successfully");
    },
    onError: () => {
      toast.error("Provider Failed to Add");
    },
  });

  const onAddHandler = (data) => {
    console.log(data);
    addProvider(data);
  };

  const providers = useQuery(
    ["onBoardProviderList"],
    () => api.getAllProviders(),
    {
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  const onRowClick = () => {};

  return (
    <React.Fragment>
      <Container className="mt-5 mb-5">
        <HeaderMain
          title="Provider Onboarding"
          subTitle="Please add any new providers, not already seen in the table."
        />

        <div className="mt-5">
          {providers.isLoading === false ? (
            <div
              className="p-3 mb-5"
              style={{ border: "1px solid lightgrey", borderRadius: "5px" }}
            >
              <h3 className="fw-light">Providers Table</h3>
              <ReactTable
                columns={COLUMNS}
                data={providers.data}
                getOnClick={onRowClick}
                isLoading={false}
              />
            </div>
          ) : (
            <></>
          )}
          <h3>Add a Provider Below:</h3>
          <Form
            onSubmit={(dataObj) => {
              if (_.isEmpty(dataObj)) {
              } else {
                onAddHandler(dataObj);
              }
            }}
            mutators={{
              // potentially other mutators could be merged here
              ...arrayMutators,
            }}
          >
            {({ handleSubmit, form, submitting, pristine }) => (
              <FormReact
                onSubmit={async (event) => {
                  const error = await handleSubmit(event);
                  console.log("Error not in resolved promise", error);
                  if (error) {
                    return error;
                  }
                  form.reset();
                }}
                className="mb-3"
                // style={{ width: "75%", height: "100%" }}
              >
                <div className="d-flex mt-4" style={{ width: "100%" }}>
                  <div className="me-3" style={{ width: "40%" }}>
                    <h5 className="fw-light">First Name</h5>
                    <Field name="first_name">
                      {({ input }) => (
                        <input
                          className="p-2"
                          style={{
                            width: "100%",
                            border: "1px lightgrey solid",
                            borderRadius: "10px",
                          }}
                          placeholder=""
                          type="text"
                          {...input}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="me-3" style={{ width: "40%" }}>
                    <h5 className="fw-light">Last Name</h5>
                    <Field name="last_name">
                      {({ input }) => (
                        <input
                          className="p-2"
                          style={{
                            width: "100%",
                            border: "1px lightgrey solid",
                            borderRadius: "10px",
                          }}
                          placeholder=""
                          type="text"
                          {...input}
                        />
                      )}
                    </Field>
                  </div>

                  <div style={{ width: "20%" }}>
                    <h5 className="fw-light">Title</h5>
                    <Field name="title">
                      {({ input }) => (
                        <input
                          className="p-2"
                          style={{
                            width: "100%",
                            border: "1px lightgrey solid",
                            borderRadius: "10px",
                          }}
                          placeholder=""
                          type="text"
                          {...input}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className="d-flex mt-4" style={{ width: "100%" }}>
                  <div className="" style={{ width: "60%" }}>
                    <h5 className="mt-4 fw-light">Provider Specialty</h5>
                    <Field name="type">
                      {({ input }) => (
                        <input
                          className="p-2"
                          style={{
                            width: "100%",
                            border: "1px lightgrey solid",
                            borderRadius: "10px",
                          }}
                          placeholder=""
                          type="text"
                          {...input}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="ms-3" style={{ width: "40%" }}>
                    <h5 className="mt-4 fw-light">Provider Phone Number</h5>
                    <Field name="phone">
                      {({ input }) => (
                        <input
                          className="p-2"
                          style={{
                            width: "100%",
                            border: "1px lightgrey solid",
                            borderRadius: "10px",
                          }}
                          placeholder=""
                          type="text"
                          {...input}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <h5 className="mt-4 fw-light">Provider Practice Name</h5>
                <Field name="name">
                  {({ input }) => (
                    <input
                      className="p-2"
                      style={{
                        width: "100%",
                        border: "1px lightgrey solid",
                        borderRadius: "10px",
                      }}
                      placeholder=""
                      type="text"
                      {...input}
                    />
                  )}
                </Field>

                <div className="d-flex mt-4" style={{ width: "100%" }}>
                  <div className="me-3" style={{ width: "40%" }}>
                    <h5 className="fw-light">Provider Practice Address</h5>
                    <Field name="address">
                      {({ input }) => (
                        <input
                          className="p-2"
                          style={{
                            width: "100%",
                            border: "1px lightgrey solid",
                            borderRadius: "10px",
                          }}
                          placeholder=""
                          type="text"
                          {...input}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="me-3" style={{ width: "30%" }}>
                    <h5 className="fw-light">Provider Practice City</h5>
                    <Field name="city">
                      {({ input }) => (
                        <input
                          className="p-2"
                          style={{
                            width: "100%",
                            border: "1px lightgrey solid",
                            borderRadius: "10px",
                          }}
                          placeholder=""
                          type="text"
                          {...input}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="me-3" style={{ width: "20%" }}>
                    <h5 className="fw-light">Provider Practice State</h5>
                    <Field name="state">
                      {({ input }) => (
                        <input
                          className="p-2"
                          style={{
                            width: "100%",
                            border: "1px lightgrey solid",
                            borderRadius: "10px",
                          }}
                          placeholder=""
                          type="text"
                          {...input}
                        />
                      )}
                    </Field>
                  </div>

                  <div style={{ width: "10%" }}>
                    <h5 className="fw-light">Zip</h5>
                    <Field name="zip">
                      {({ input }) => (
                        <input
                          className="p-2"
                          style={{
                            width: "100%",
                            border: "1px lightgrey solid",
                            borderRadius: "10px",
                          }}
                          placeholder=""
                          type="text"
                          {...input}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <button
                  className="btn btn-lg text-light mt-5"
                  style={{ backgroundColor: "#107869", width: 256 }}
                  type="submit"
                  disabled={submitting || pristine}
                >
                  Create Provider
                </button>

                <button
                  className="btn btn-lg text-light mt-5 ms-3"
                  style={{ backgroundColor: "#f7ab48", width: 256 }}
                  type="button"
                  disabled={submitting || pristine}
                  onClick={() => form.reset()}
                >
                  Reset Form
                </button>
              </FormReact>
            )}
          </Form>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default ProviderOnboarding;
