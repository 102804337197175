// Root
import React, { useEffect, useState } from "react";

// External Libraries
import { Container } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import { HeaderMain } from "./common/UI/headerMain";
import ReactTable from "./common/UI/reactTable";

// Assets
import { COLUMNS } from "./reacttable/patientColumns";

const Patients = (props) => {
  const [list, setList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [columns, setColumns] = useState(COLUMNS);
  const [caremanager, setCareManager] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;

  useEffect(() => {}, []);

  const onRowClick = (params) => {
    console.log(params);
    navigate(`/patient`, {
      state: { id: params.id },
    });
  };

  const exportFiles = () => {
    console.log("TO-DO EXPORT FEATURES");
  };

  return (
    <React.Fragment>
      {/* Header & Sub Header*/}
      <Container className="mt-5 mb-5">
        <HeaderMain
          title="Assigned Patients"
          subTitle="Here are the patients that are assigned to you. Click on a patient to view their details."
        />
      </Container>

      <Container>
        {isLoading == true ? (
          <div class="d-flex align-items-center text-primary">
            <strong>Loading...</strong>
            <div
              class="spinner-border ms-auto"
              role="status"
              aria-hidden="true"
            ></div>
          </div>
        ) : (
          <div className="mb-5" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <ReactTable
              columns={columns}
              data={data.data}
              getOnClick={onRowClick}
              isLoading={false}
              exportCSV={exportFiles}
              exportPDF={exportFiles}
            />
            {/* {list.length === 0 ? (
              <h4 className="text-center font-italic">No Patients Found</h4>
            ) : (
              <></>
            )} */}
          </div>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Patients;
