import React from "react";

const CompletedNotifications = () => {
  return (
    <React.Fragment>
      <h1>Hello World!</h1>
    </React.Fragment>
  );
};

export default CompletedNotifications;
