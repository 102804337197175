import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const TMP = () => {
  return (
    <React.Fragment>
      <Container className="bg-success" style={{ height: "100vh" }}>
        <Row style={{ position: "relative" }}>
          <Col md={2} className="bg-primary" style={{ position: "fixed" }}>
            <h1>Hello World!</h1>
            <h1>Hello World!</h1>
            <h1>Hello World!</h1>
            <h1>Hello World!</h1>
            <h1>Hello World!</h1>
          </Col>
          <Col
            md={10}
            className="bg-primary"
            style={{ position: "absolute", width: "100%" }}
          >
            <h1>Hello World!</h1>
            <h1>Hello World!</h1>
            <h1>Hello World!</h1>
            <h1>Hello World!</h1>
            <h1>Hello World!</h1>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default TMP;
