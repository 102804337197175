import React from "react";

const LoadingSpinner = () => {
  return (
    <div class="d-flex align-items-center" style={{ color: "#f7ab48" }}>
      <strong>Loading...</strong>
      <div
        class="spinner-border ms-auto"
        role="status"
        aria-hidden="true"
      ></div>
    </div>
  );
};

export default LoadingSpinner;
