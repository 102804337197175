// Root
import "./App.css";

// External Libraries
import { Route, Routes } from "react-router-dom";

// Amplify
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

// Base Components
import NavBar from "./components/common/navbar";

// Components
import Home from "./components/home";
import Patients from "./components/patients";
import Notifications from "./components/notifications";
import Notification from "./components/common/notification";
import NotFound from "./components/common/notFound";
import CareManagerOnBoarding from "./components/admin/careManager";
import OnBoarding from "./components/admin/onBoarding";
import CreateNotification from "./components/common/createNotification";
import PatientProfile from "./components/common/patientProfile";
import CompletedNotifications from "./components/common/completedNotifications";
import PatientOnBoarding from "./components/admin/patientOnBoarding";
import ProviderOnboarding from "./components/admin/providerOnboarding";
import FacilityOnboarding from "./components/admin/facilityOnboarding";
import TMP from "./components/admin/tmp";
import Pod from "./components/pod";
import YourFacilities from "./components/yourFacilities";

async function signOut() {
  try {
    await Auth.signOut();
    window.location.reload();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

function App() {
  return (
    <div className="App">
      <NavBar handleLogout={signOut} />

      <Routes>
        {/* Main Roots */}
        <Route path="/home" element={<Home />} />

        {/* Home Card Roots */}
        <Route path="/patients" element={<Patients />} />
        <Route path="/patient" element={<PatientProfile />} />
        <Route path="/pod" element={<Pod />} />
        <Route path="/facility" element={<YourFacilities />} />

        <Route path="/notifications" element={<Notifications />} />
        <Route path="/notification" element={<Notification />} />
        <Route
          path="/completednotification/:id"
          element={<CompletedNotifications />}
        />
        <Route path="/createnotification" element={<CreateNotification />} />

        {/* Development Roots DELETE ON PRODUCTION */}
        <Route path="/tmp" element={<TMP />} />

        {/* Admin Roots */}
        <Route
          path="/admin/onboard/caremanager"
          element={CareManagerOnBoarding}
        />
        <Route path="/admin/onboard/patient" element={<PatientOnBoarding />} />
        <Route
          path="/admin/onboard/provider"
          element={<ProviderOnboarding />}
        />
        <Route
          path="/admin/onboard/facility"
          element={<FacilityOnboarding />}
        />
        <Route path="/admin/onboard" element={<OnBoarding />} />

        {/* Additional Roots */}
        <Route path="/" exact element={<Home />} />
        {/* <Redirect to="/not-found" /> */}
        <Route path="/not-found" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default withAuthenticator(App);
