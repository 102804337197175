import React from "react";
import { NavLink, Link } from "react-router-dom";

import logo from "../../assets/logo.png";

const NavBar = (props) => {
  const { handleLogout } = props;

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white mb-2">
      <Link className="navbar-brand ms-5" to="/">
        <img src={logo} width="100"/>
        
      </Link>

      <div className="navbar-nav ms-auto">
        <NavLink className="nav-item nav-link" to="/home">
          Home
        </NavLink>

        <NavLink className="nav-item nav-link" to="/admin/onboard">
          Admin
        </NavLink>

        <button
          onClick={handleLogout}
          className="btn btn-sm me-5 ms-2 m-1"
          style={{ color: "#107869" }}
          to="/home"
        >
          Sign Out
        </button>
      </div>
    </nav>
  );
};

export default NavBar;
