import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const getUser = (params) =>
  api.post("user", params).then((res) => res.data);

export const getCareUsers = (params) =>
  api.get("user", params).then((res) => res.data);

export const getFacilities = (params) =>
  api.get("facility", params).then((res) => res.data);

export const getPatientInstance = (params) =>
  api.post("patient/instance", params).then((res) => res.data);

export const getPatients = (params) =>
  api.post("patient", params).then((res) => res.data);

export const getAllAlerts = (params) =>
  api.get("alert", params).then((res) => res.data);

export const getAlerts = (params) =>
  api.post("user/alert", params).then((res) => res.data);

export const getPatientCareAlerts = (params) =>
  api.post("user/care", params).then((res) => res.data);

export const completeAlert = (params) =>
  api.post("alert/complete", params).then((res) => res.data);

export const sendCareAlert = (params) =>
  api.put("user/care", params).then((res) => res.data);

export const addMeds = (params) =>
  api.post("addmed", params).then((res) => res.data);

export const addAppts = (params) =>
  api.post("addappt", params).then((res) => res.data);

export const addPatient = (params) =>
  api.put("patient", params).then((res) => res.data);

export const cognitoPatientLogin = (params) =>
  api.post("admin/cognito", params).then((res) => res.data);

export const updatePatientProfile = (params) => 
  api.post("patient/update", params).then((res) => res.data);
