import React, { useState } from "react";

// Third Party Libraries
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Modal, Form as FormReact, Card } from "reactstrap";
import { Form, Field } from "react-final-form";
import RenderDatePicker from "../UI/RenderDatePicker";
import arrayMutators from "final-form-arrays";
import _, { each } from "lodash";
import { toast } from "react-toastify";

// Components
import ReactTable from "../UI/reactTable";
import LoadingSpinner from "../UI/loadingSpinner";

// Services
import * as api from "../../../service/visitService";
import * as facilityAPI from "../../../service/facilityService";

const Visits = ({ id, providers }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);

  const { data, isLoading, isFetching } = useQuery(
    ["visits"],
    () => api.getVisit({ id }),
    {
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  const queryClient = useQueryClient();

  const { mutate: addVisit } = useMutation(api.addVisit, {
    onSuccess: () => {
      toast.success("Appointment Added Successfully");
      queryClient.invalidateQueries("visits");
    },
    onError: () => {
      toast.error("Appointment Failed to Add");
    },
  });

  const { mutate: deleteVisit } = useMutation(api.deleteVisit, {
    onSuccess: () => {
      toast.success("Appointment Successfully Deleted");
      queryClient.invalidateQueries("visits");
    },
    onError: () => {
      toast.error("Appointment Failed to Delete");
    },
  });

  const onRowClick = () => {};

  const onAddHandler = (data) => {
    data.patient = id;

    addVisit(data);
    setShowAddModal(false);
  };

  const onDeleteHandler = (id) => {
    let obj = {};
    obj.visit_id = id;

    deleteVisit(obj);
  };

  const checkUpcoming = (params) => {
    let date = new Date();
    let paramDate = new Date(params);

    if (date <= paramDate)
      return "Yes"
    else
       return "No"
  };

  return (
    <div>
      {isLoading === true ? (
        <LoadingSpinner />
      ) : (
        <React.Fragment>
          <div className="d-flex mb-3">
            <h4 className="fw-bold">Appointments</h4>
            <button
              className="btn btn-lg text-light ms-auto"
              style={{ backgroundColor: "#107869", width: 256 }}
              onClick={() => setShowAddModal(true)}
            >
              Add Appointment
            </button>
          </div>
          <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            {/* <ReactTable
                        onDeleteHandler={onDeleteHandler}
                        columns={COLUMNS}
                        data={data}
                        getOnClick={onRowClick}
                        isLoading={false}
                    /> */}

            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="fw-bold">Facility</th>
                  <th className="fw-bold">Address</th>
                  <th className="fw-bold">Date</th>
                  <th className="fw-bold">Time</th>
                  <th className="fw-bold">Upcoming?</th>
                  <th className="fw-bold">Reason</th>
                  <th className="fw-bold">Provider</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map((ea) => (
                  <tr>
                    <td>{ea.facility}</td>
                    <td>
                      {ea.address} {ea.city} {ea.state} {ea.zip}
                    </td>
                    <td>{ea.date}</td>
                    <td>{ea.time}</td>
                    <td>{checkUpcoming(ea.date)}</td>
                    <td>{ea.reason}</td>
                    <td>{ea.provider}</td>
                    <td>
                      <i
                        className="fa fa-trash"
                        onClick={() => onDeleteHandler(ea.id)}
                        style={{ cursor: "pointer", color: "#f7ab48" }}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p className="text-muted fw-light fst-italic">
              {isFetching
                ? "Refreshing..."
                : "Refreshed at " + new Date().toLocaleTimeString()}
            </p>
          </div>

          <Modal
            isOpen={showAddModal}
            ize="lg"
            centered
            toggle={() => setShowAddModal(!showAddModal)}
            size="lg"
          >
            <div className="m-5">
              <div className="d-flex">
                <h1>Add Appointment</h1>
                <button
                  className="btn btn-md ms-auto"
                  type="button"
                  onClick={() => setShowAddModal(false)}
                >
                  <i className="fa fa-close fa-2x" style={{ color: "#f7ab48" }}></i>
                </button>
              </div>
              <hr></hr>
              <div className="d-flex">
                <Card className="p-3 me-2 bg-light" style={{ width: "30%" }}>
                  <p className="fw-bold">Appointment Info</p>
                  <p className="text-muted">Add Appointment to the left</p>
                </Card>
                <div className="ps-4" style={{ width: "70%" }}>
                  <Form
                    onSubmit={(dataObj) => {
                      console.log(dataObj);

                      if (_.isEmpty(dataObj)) {
                        setShowAddModal(false);
                      } else {
                        onAddHandler(dataObj);
                      }
                    }}
                    mutators={{
                      // potentially other mutators could be merged here
                      ...arrayMutators,
                    }}
                  >
                    {({ handleSubmit }) => (
                      <FormReact
                        onSubmit={handleSubmit}
                        className=""
                        // style={{ width: "100%", height: "100%" }}
                      >
                        <Field name="provider">
                          {({ input }) => (
                            <select
                              className="form-control mt-2 p-2"
                              name={input.name}
                              style={{
                                width: "100%",
                                border: "1px lightgrey solid",
                                borderRadius: "10px",
                              }}
                              onChange={(value) => input.onChange(value)}
                            >
                              <option key="none" value="none">
                                Choose the Provider
                              </option>
                              {providers.map((ea) => (
                                <option
                                  key={ea.id}
                                  value={`${ea.first_name} ${ea.last_name} MD`}
                                >
                                  {ea.first_name} {ea.last_name}
                                </option>
                              ))}
                            </select>
                          )}
                        </Field>

                        <h5 className="mt-4 fw-light">Facility Name</h5>
                        <Field name="facility">
                          {({ input }) => (
                            <input
                              className="p-2"
                              style={{
                                width: "100%",
                                border: "1px lightgrey solid",
                                borderRadius: "10px",
                              }}
                              placeholder=""
                              type="text"
                              {...input}
                            />
                          )}
                        </Field>

                        <div className="d-flex mt-4" style={{ width: "100%" }}>
                          <div className="me-3" style={{ width: "100%" }}>
                            <h5 className="fw-light">Address</h5>
                            <Field name="address">
                              {({ input }) => (
                                <input
                                  className="p-2"
                                  style={{
                                    border: "1px lightgrey solid",
                                    borderRadius: "10px",
                                  }}
                                  placeholder=""
                                  type="text"
                                  {...input}
                                />
                              )}
                            </Field>
                          </div>

                          <div className="me-3" style={{ width: "100%" }}>
                            <h5 className="fw-light">City</h5>
                            <Field name="city">
                              {({ input }) => (
                                <input
                                  className="p-2"
                                  style={{
                                    border: "1px lightgrey solid",
                                    borderRadius: "10px",
                                  }}
                                  placeholder=""
                                  type="text"
                                  {...input}
                                />
                              )}
                            </Field>
                          </div>
                        </div>

                        <div className="d-flex mt-4">
                          <div className="me-3">
                            <h5 className="fw-light">State</h5>
                            <Field name="state">
                              {({ input }) => (
                                <input
                                  className="p-2"
                                  style={{
                                    border: "1px lightgrey solid",
                                    borderRadius: "10px",
                                  }}
                                  placeholder=""
                                  type="text"
                                  {...input}
                                />
                              )}
                            </Field>
                          </div>

                          <div className="me-3">
                            <h5 className="fw-light">Zip Code</h5>
                            <Field name="zip">
                              {({ input }) => (
                                <input
                                  className="p-2"
                                  style={{
                                    border: "1px lightgrey solid",
                                    borderRadius: "10px",
                                  }}
                                  placeholder=""
                                  type="text"
                                  {...input}
                                />
                              )}
                            </Field>
                          </div>
                        </div>

                        <div className="d-flex mt-4">
                          <div className="me-3">
                            <h5 className="fw-light">Appointment Date</h5>
                            <div className="">
                              <Field name="date" component={RenderDatePicker} />
                            </div>
                          </div>

                          <div className="me-3">
                            <h5 className="fw-light">Time</h5>
                            <Field name="time">
                              {({ input }) => (
                                <input
                                  className="p-2"
                                  style={{
                                    border: "1px lightgrey solid",
                                    borderRadius: "10px",
                                  }}
                                  placeholder=""
                                  type="text"
                                  required
                                  {...input}
                                />
                              )}
                            </Field>
                          </div>
                        </div>

                        <h5 className="mt-4 fw-light">Reason</h5>
                        <Field name="reason">
                          {({ input }) => (
                            <input
                              className="p-2"
                              style={{
                                width: "100%",
                                border: "1px lightgrey solid",
                                borderRadius: "10px",
                              }}
                              placeholder=""
                              type="text"
                              {...input}
                            />
                          )}
                        </Field>

                        <button
                          className="btn btn-lg text-light mt-5"
                          style={{ backgroundColor: "#107869", width: 256 }}
                          type="submit"
                        >
                          Add Appointment
                        </button>
                      </FormReact>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      )}
    </div>
  );
};

export default Visits;
