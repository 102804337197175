import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

// GET all medications, pass in patient ID
export const getVisit = (params) =>
  api.post("visit", params).then((res) => res.data);

// Add medication(s), pass medication fields
export const addVisit = (params) =>
  api.put("visit", params).then((res) => res.data);

// Delete single medication, pass medication ID
export const deleteVisit = (params) =>
  api.delete("visit", { data: params }).then((res) => res.data);